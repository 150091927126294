import React, { useEffect, useState, useMemo } from 'react'
import {Paper, TableBody, TableContainer, TableHead} from '@material-ui/core';
import { useSelector } from 'react-redux';
import MaUTable from '@material-ui/core/Table'
import { useTable, useSortBy, useExpanded } from 'react-table';
import {listColumns} from '../../utils'
import {TableColumn, TableData} from '../index'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {colors} from '../../styles'

export default function TableBoard(props) {
  const classSelected = props.data
  const students = useSelector(state => state.summaryReducer.students)
  const [studentFiltered, setStudentFiltered] = useState(students)

  const columns = useMemo(() => [
    {
      id: 'expander',
      Header: "",
      Cell: ({ row }) =>
        row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {row.isExpanded ? <ExpandMoreIcon style={{color: colors.orange, fontWeight: 'bold'}} /> : <ChevronRightIcon />}
          </span>
        ) : null,
    },
    ...listColumns
  ], [])

  let tempData = []
  for (let i = 0; i < studentFiltered.length; i++) {
    let objTemp = {
      name: '',
      grade: null,
      qtyVideo: null,
      percVideo: null,
      qtyQuiz: null,
      percQuiz: null,
      point: null,
      subRows: []
    }
    objTemp.grade = studentFiltered[i].student_level
    objTemp.name = studentFiltered[i].name
    objTemp.qtyVideo = studentFiltered[i].doneTopic
    objTemp.qtyQuiz = studentFiltered[i].doneQuiz
    objTemp.point = studentFiltered[i].leaderboard_point
    objTemp.percVideo = studentFiltered[i].percTopic
    objTemp.percQuiz = studentFiltered[i].percQuiz
    objTemp.subRows = [...studentFiltered[i].subRows]
    tempData.push(objTemp)
  }

  const data = useMemo(() => [
    ...tempData
  ], [studentFiltered])

  const { getTableProps, headerGroups, rows, prepareRow, getTableBodyProps, state: {expanded} } = useTable({
    columns,
    data,
  }, useSortBy, useExpanded)

  useEffect(() => {
    if (classSelected === 'all') {
      setStudentFiltered(students)
    } else {
      let temp = students.filter(data => {
        return data.student_level === Number(classSelected)
      })
      setStudentFiltered(temp)
    }
  }, [classSelected])

  return (
    <TableContainer style={{height: '80%', overflow: 'auto'}} component={Paper}>
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, index) => {
            return (
              <TableColumn
                key={index}
                headerGroup={headerGroup}
              />
            )
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <TableData key={i} i={i} row={row} />
            )
          })}
        </TableBody>
      </MaUTable>
    </TableContainer>
  )
}