import React from 'react'
import {IconButton, Badge, makeStyles, Tooltip, Zoom} from '@material-ui/core'
import {useSelector} from 'react-redux'
import {colors} from '../../styles'
import GetAppIcon from '@material-ui/icons/GetApp'

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '35px',
    color: colors.blue,
    "@media (max-width: 540px)": {
      fontSize: '25px'
    }
  }
}))

export default function AnnouncementButton({setshowOpening}) {
  const classes = useStyles()
  const tokenPendings = useSelector(state => state.tokenReducer.pending)

  return (
    <Tooltip title="Download token yang belum diaktivasi" arrow TransitionComponent={Zoom}>
      <IconButton onClick={() => setshowOpening(true)}>
        {tokenPendings.length > 0 ?
        (
          <Badge badgeContent={tokenPendings.length} color="error">
            <GetAppIcon className={classes.icon} />
          </Badge>
        ) :
        (
          <GetAppIcon className={classes.icon} />
        )}
      </IconButton>
    </Tooltip>
  )
}