import React from 'react'
import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody, 
  Paper, makeStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  }
}))

export default function TableTeacher({teachers}) {
  const classes = useStyles()

  return (
    <TableContainer component={Paper} 
      style={{
        height: '45vh',
        overflow: 'auto'
      }}
      data-intro='Untuk melihat daftar guru yang dapat mengakses Dashboard Maupintar' data-step="1"
    >
      <Table className={classes.table} aria-label="list-member">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{fontWeight: 'bold'}} >Nama</TableCell>
            <TableCell align="center" style={{fontWeight: 'bold'}} >Email</TableCell>
            <TableCell align="center" style={{fontWeight: 'bold'}} >Nomor WA</TableCell>
            <TableCell align="center" style={{fontWeight: 'bold'}} >Jabatan</TableCell>
            <TableCell align="center" style={{fontWeight: 'bold'}} >Kelas</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teachers.map((teacher, index) => (
            <TableRow key={index}>
              <TableCell align="left" style={{fontWeight: 'bold'}}>
                {teacher.fullname}
              </TableCell>
              <TableCell align="left">{teacher.email}</TableCell>
              <TableCell align="left">{teacher.phone}</TableCell>
              <TableCell align="center">{teacher.role}</TableCell>
              <TableCell align="center">Kelas {teacher.level}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}