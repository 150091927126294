import React from 'react'
import {MenuButton, AnnouncementButton} from '../index'

export default function ButtonTopbar({setshowOpening, setOpenMenu, openMenu}) {
  return (
    <div className={"row-center"}>
      <AnnouncementButton setshowOpening={setshowOpening} />
      <MenuButton setOpenMenu={setOpenMenu} openMenu={openMenu} />
    </div>
  )
}