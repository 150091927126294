import React from 'react'
import {Modal, Fade, Paper, Typography, Backdrop, makeStyles, Button} from '@material-ui/core'
import {colors} from '../../styles'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
    color: colors.orange,
    marginBottom: '20px',
    textAlign: 'center',
    "@media (max-width: 768px)": {
      fontSize: '18px'
    }
  },
  paper: {
    width: '50%', 
    padding: '20px', 
    maxHeight: '80%', 
    overflowY: 'auto',
    "@media (max-width: 768px)": {
      width: '80%'
    }
  }
}))

export default function ModalCompleteToken({showOpening, setshowOpening}) {
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`modal row-center`}
      open={showOpening}
      onClose={() => setshowOpening(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showOpening}>
        <Paper className={`${classes.paper} column-up`}>
          <Typography variant="h1" className={classes.title}>
            Semua Token telah diaktivasi
          </Typography>
          <img src="./images/maupintar-senang.png" style={{width: '100px', marginBottom: '20px'}} />
          <Button color="secondary" variant="contained" onClick={() => setshowOpening(false)}>OK</Button>
        </Paper>
      </Fade>
    </Modal>
  )
}