import axios from "axios";

export function getQuestion(quiz_id, setQuiz, setLoading) {
  return (dispatch, getState) => {
    const Form = new FormData();
    Form.append('quiz_id', quiz_id);

    axios({
      method: 'POST',
      url: 'https://maupintar.id/app_api/get_quiz_questions.php',
      data: Form
    })
    .then(response => {
      setQuiz(response.data);
      setLoading(false);
    })
    .catch(error => {
      console.log('error get quiz >>>> ', error);
    })
  }
}