import React from 'react'
import {Avatar, Typography, makeStyles} from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import WorkIcon from '@material-ui/icons/Work'
import ApartmentIcon from '@material-ui/icons/Apartment'

const useStyles = makeStyles((theme) => ({
  containerGeneralInfo: {
    width: '20%',
    height: '88vh',
    paddingTop: '1vw',
    zIndex: '-1',
    "@media (max-width: 768px)": {
      display: 'none'
    }
  }
}))

export default function ProfileInformation({profile}) {
  const classes = useStyles()

  return (
    <div className={`${classes.containerGeneralInfo} column-up`}>
      <Avatar 
        alt={profile.student_name} 
        src={profile.photo_url} 
        style={{height: '10vw', width: '10vw'}}
      />
      <div style={{paddingLeft: '1vw', marginBottom: '1vw'}}>
        <Typography
          variant="h5"
          style={{fontWeight: 'bold', textAlign: 'center'}}
        >
          {profile.student_name}
        </Typography>
        <div style={{color: '#fa4c06', display: 'flex'}}>
          <LocationOnIcon style={{marginRight: '1vw'}} />
          <Typography align="left">{profile.homecity}</Typography>
        </div>
        <div style={{color: '#fa4c06', display: 'flex'}}>
          <WorkIcon style={{marginRight: '1vw'}} />
          <Typography align="left">{profile.role}</Typography>
        </div>
        <div style={{color: '#fa4c06', display: 'flex'}}>
          <ApartmentIcon style={{marginRight: '1vw'}} />
          <Typography align="left">{profile.school}</Typography>
        </div>
      </div>
    </div>
  )
}