import React from 'react'
import {makeStyles, Typography} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  containerDescription: {
    width: '55%',
    "@media (max-width: 768px)": {
      width: '90%'
    }
  }
}))

export default function ModulDescription() {
  const classes = useStyles()

  return (
    <div className={classes.containerDescription}>
      <Typography variant="h5" align="left" style={{fontWeight: 'bold'}}>
        Download Modul User Guide
      </Typography>
      <Typography variant="h6" align="left">
        Untuk membantu Anda menguasai penggunaan fitur-fitur dalam ekosistem Maupintar, 
        tersedia modul untuk Guru dan Siswa
      </Typography>
      <div className={'row-left-up'}>
        <Typography variant="h6" align="left" style={{marginRight: '1rem'}}>a.</Typography>
        <Typography variant="h6" align="left">
          <b>Modul Guru</b> : berisi penjelasan detil fitur-fitur dalam Dashboard Guru di Web Maupintar
        </Typography>
      </div>
      <div className={'row-left-up'}>
        <Typography variant="h6" align="left" style={{marginRight: '1rem'}}>b.</Typography>
        <Typography variant="h6" align="left">
          <b>Modul Siswa</b> : berisi penjelasan fitur-fitur pada Aplikasi Maupintar
        </Typography>
      </div>
    </div>
  )
}