import React from 'react'
import {Card, CardContent, Accordion, AccordionSummary, 
  Typography, AccordionDetails, makeStyles
} from '@material-ui/core'
import parse from 'html-react-parser'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '50%',
    margin: '2vw 0',
    boxShadow: '0px 0px 20px 5px rgba(54, 53, 53, 0.3)',
    borderRadius: '15px',
    "@media (max-width: 1024px)": {
      width: '60%'
    },
    "@media (max-width: 768px)": {
      width: '90%'
    }
  },
  summary: {
    fontWeight: 'bold',
    fontSize: 30,
    "@media (max-width: 767px)": {
      fontSize: 14
    }
  },
  contentSummary: {
    fontSize: 20,
    textAlign: 'justify',
    "@media (max-width: 767px)": {
      fontSize: 12
    }
  }
}))

export default function ModulSummary({modulDetail}) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <Accordion style={{boxShadow: "none"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5" className={classes.summary}>
              Ringkasan
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.contentSummary}>
              {parse(modulDetail.course_content.post_content)}
            </div>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  )
}