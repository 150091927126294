import React, {useMemo} from 'react'
import {useTable, useSortBy} from 'react-table'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  columnStyle: {
    borderBottom: 'solid 3px red',
    background: 'aliceblue',
    color: 'black',
    fontWeight: 'bold'
  },
  rowStyle: {
    padding: '10px',
    border: 'solid 1px gray',
    background: 'papayawhip'
  }
}))

export default function TokenTable({lists}) {
  const classes = useStyles()
  const data = useMemo(() => lists, [lists])
  const columns = useMemo(() => [
    {
      Header: 'Token',
      accessor: 'col1'
    },
    {
      Header: 'Kelas',
      accessor: 'col2'
    }
  ], [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({columns, data}, useSortBy)

  return (
    <table {...getTableProps()} style={{ border: 'solid 1px blue' }}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            <th className={classes.columnStyle}>No</th>
            {headerGroup.headers.map(column => (
              <th 
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={classes.columnStyle}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              <td className={classes.rowStyle}>{index+1}</td>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={classes.rowStyle}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}