import React from 'react'
import {makeStyles, FormControl, InputLabel, Input} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles((theme) => ({
  containerSearch: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 2vw',
    marginBottom: '2vh'
  },
  margin: {
    margin: theme.spacing(1),
    width: '15vw',
    "@media (max-width: 767px)": {
      width: '25vw'
    }
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: '25px',
      borderStyle: 'none',
      backgroundColor: 'rgba(250, 76, 6, 0.75)',
      height: '7vh'
    }
  }
}))

export default function SearchInput({setKeyword, keyword}) {
  const classes = useStyles()

  return (
    <div className={classes.containerSearch}>
      <FormControl 
        className={classes.margin + ' ' + classes.textField}
        data-intro='Fitur pencarian data guru' data-step="2"
      >
        <InputLabel htmlFor="standard-search">
          <div style={{
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            color: 'rgba(250, 76, 6, 0.75)'
            }}
          >
            <SearchIcon /> Cari
          </div>
        </InputLabel>
        <Input
          id="standard-search"
          style={{color: 'rgba(250, 76, 6, 0.75)'}}
          type={'text'}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </FormControl>
    </div>
  )
}