import React from 'react'
import {makeStyles, Typography} from '@material-ui/core'
import {PopoverAmountCourse} from '../index'

const useStyles = makeStyles((theme) => ({
  cardStat: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '70%',
    cursor: 'pointer',
    transition: theme.transitions.create(
      ["transform"],
      { duration: theme.transitions.duration.complex }
    ),
    "&:hover": {
      transform: 'scale(1.2)'
    }
  },
  titleStat: {
    color: 'rgb(61, 61, 61)',
    fontWeight: 'bold',
    fontSize: '17px',
    "@media (max-width: 768px)": {
      fontSize: '14px'
    },
    "@media (max-width: 540px)": {
      fontSize: '12px'
    }
  },
  countStat: {
    fontWeight: 'bold',
    color: 'rgba(250, 76, 6, 1)', 
    fontSize: '45px',
    "@media (max-width: 540px)": {
      fontSize: '30px'
    }
  }
}))

export default function AmountCourseContent({
  handlePopoverClose, handlePopoverOpen, id, data, idIcon, open, anchorEl
}) {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.cardStat}
        id={id}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        data-intro={
          id === 'topic' ? 
          'Jumlah Total Topik Matematika yang tersedia di level ini' :
          (id === 'video' ?
            'Jumlah Total Video yang dapat Anda akses di level ini' :
            'Jumlah Total Kuis yang dapat Anda akses di level ini'
          )
        } 
        data-step={
          id === 'topic' ? 
          "2" :
          (id === 'video' ?
            "3" : "4"
          )
        }
      >
        <Typography className={classes.titleStat}>
          {
            id === 'topic' ? 
            'Total Topik' :
            (id === 'video' ?
              'Total Video' : 'Total Kuis'
            )
          }
        </Typography>
        <Typography variant="h3" className={classes.countStat}>
          {id === 'topic' ?
            data.topic :
            (id === 'video' ?
              data.video : data.quiz
            )
          }
        </Typography>
      </div>
      <PopoverAmountCourse 
        idIcon={idIcon} 
        open={open} 
        anchorEl={anchorEl} 
        handlePopoverClose={handlePopoverClose} 
      />
    </div>
  )
}