import React from 'react'
import {Modal, Fade, Backdrop} from '@material-ui/core'
import {VideoStepper} from '../index'

export default function ModalOpening({showOpening, closeOpening}) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={"modal row-center"}
      open={showOpening}
      onClose={closeOpening}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout: 500}}
    >
      <Fade in={showOpening}>
        <VideoStepper closeOpening={closeOpening} />
      </Fade>
    </Modal>
  )
}