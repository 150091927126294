import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {getMTVCourses} from '../actions'
import {LoadingPage} from './index'
import Cookies from 'js-cookie'
import {getToken} from '../actions'
import {colors} from '../styles'
import {BtnClassCategory, BtnChooseClass, CardMTV, EmptyMTV} from '../components'

const activeButton = {
  backgroundColor: colors.orange,
  color: 'white'
}

const useStyles = makeStyles((theme) => ({
  cardDeck: {
    flexWrap: 'wrap',
    height: '85%%'
  }
}))

export default function VideoPage() {
  const classes = useStyles()
  const valueClass = ['all', 1, 2, 3, 4, 5, 6]
  const [active, setActive] = useState('all')
  const videos = useSelector(state => state.videoReducer.videos)
  const loading = useSelector(state => state.videoReducer.loadingVideo)
  const [videoFiltered, setVideoFiltered] = useState(videos)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    // Cek apakah sudah login atau belum (bisa refactoring dengan menggunakan useContext)
    if (Cookies.get('cks-mp')) {
      dispatch(getToken())
      const form = new FormData()
      form.append('id_user', Cookies.get('id-mp'))
      dispatch(getMTVCourses(form))
      return () => {
        dispatch({ type: 'UPDATE/LOADING_VIDEO', payload: true })
      }
    } else {
      history.push('/login')
    }
  }, [])

  useEffect(() => {
    if (active !== 'all') {
      let temp = videos.filter(video => {
        return Number(video.grade) === Number(active);
      })
      setVideoFiltered(temp)
    } else {
      setVideoFiltered(videos)
    }
  }, [active, loading, videos])

  function viewCourse(data) {
    dispatch({ type: 'GET/VIDEO_CURRENT', payload: data })
    history.push(`/video/${data.id}`)
  };

  if (loading) {
    return (
      <LoadingPage />
    )
  }

  return (
    <div className={"page mainpage"}>
      <div 
        style={{marginTop: '2vh'}} 
      >
        {valueClass.map((value, index) => {
          return (
            <BtnClassCategory 
              value={value} 
              key={index} 
              active={active} 
              setActive={setActive} 
              activeButton={activeButton}
            />
          )
        })}
        <BtnChooseClass active={active} setActive={setActive} valueClass={valueClass} />
      </div>
      <div className={`${classes.cardDeck} row-up`}>
        {videoFiltered.length > 0 ?
        (
          videoFiltered.map((video, index) => {
            return (
              <CardMTV 
                key={index} 
                video={video} 
                index={index} 
                viewCourse={viewCourse} 
                activeButton={activeButton}
              />
            )
          })
        ) :
        (
          <EmptyMTV />
        )}
      </div>
    </div>
  );
};