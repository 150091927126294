import React from 'react'
import {FormControl, InputLabel, Select, MenuItem, makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '25vw',
    minWidth: '250px'
  },
  labelRoot: {
    fontSize: 14,
    textAlign: 'left'
  },
  input: {
    fontSize: 14
  }
}))

export default function InputRole({data, dataForm, changeValue}) {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label" className={classes.labelRoot}>
        Jabatan
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={data}
        value={(dataForm.filter(role => (
            role.id === data && role.role
          ))[0]) ?
          (
            dataForm.filter(role => (
              role.id === data && role.role
            ))[0].role
          ) : null}
        onChange={(e, id, key) => changeValue(e, data, 'role')}
        className={classes.labelRoot}
        required
      >
        <MenuItem value={'Pimpinan/Yayasan'} className={classes.input}>
          Pimpinan/Yayasan
        </MenuItem>
        <MenuItem value={'Direktur'} className={classes.input}>
          Direktur
        </MenuItem>
        <MenuItem value={'Principal/Kepala Sekolah'} className={classes.input}>
          Principal/Kepala Sekolah
        </MenuItem>
        <MenuItem value={'Manager'} className={classes.input}>
          Manager
        </MenuItem>
        <MenuItem value={'Wali Kelas'} className={classes.input}>
          Wali Kelas
        </MenuItem>
        <MenuItem value={'Guru'} className={classes.input}>
          Guru
        </MenuItem>
      </Select>
    </FormControl>
  )
}