import axios from "axios";

export function getListTeachers(profile) {
  return (dispatch, getState) => {
    const Form = new FormData();
    Form.append('sid', profile.id_school);
    axios({
      method: 'POST',
      url: 'https://maupintar.id/app_api/get_pic_list.php',
      data: Form
    })
    .then(response => {
      dispatch({ type: 'GET/TEACHERS', payload: response.data })
      dispatch({ type: 'UPDATE/LOADING', payload: false })
    })
    .catch(error => {
      console.log('error >>>> ', error)
    })
  }
}