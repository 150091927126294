let initialState = {
  videos: [],
  loadingVideo: true,
  mtvContent: null,
  loadingMtvContent: true,
  subtopics: null,
  lastChosen: null,
  videoCurrent: null,
  openingLoaderMtvDetail: true,
  subtopicTitle: ''
}

export function videoReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET/VIDEOS':
      return { ...state, videos: action.payload };
    case 'UPDATE/LOADING_VIDEO':
      return { ...state, loadingVideo: action.payload };
    case 'GET/MTV_CONTENT':
      return { ...state, mtvContent: action.payload };
    case 'UPDATE/LOADING_MTV_CONTENT':
      return { ...state, loadingMtvContent: action.payload };
    case 'GET/SUBTOPIC':
      return { ...state, subtopics: action.payload };
    case 'UPDATE/LAST_CHOSEN':
      return { ...state, lastChosen: action.payload };
    case 'GET/VIDEO_CURRENT':
      return { ...state, videoCurrent: action.payload };
    case 'UPDATE/LOADING_OPEN_MTV_CONTENT':
      return { ...state, openingLoaderMtvDetail: action.payload };
    case 'UPDATE/SUBTOPIC_TITLE':
      return { ...state, subtopicTitle: action.payload };
    default:
      return state;
  }
}