import React from 'react'
import {Popover, makeStyles, Typography} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paperTotal: {
    padding: theme.spacing(1),
    "@media (max-width: 500px)": {
      marginLeft: 0,
      marginRight: 50
    }
  },
  paperActivated: {
    height: 'auto',
    marginRight: -70,
    padding: theme.spacing(1),
    "@media (max-width: 500px)": {
      marginLeft: 0,
      marginRight: 50
    }
  },
  paperNotYet: {
    height: 'auto',
    marginLeft: 100,
    marginRight: -300,
    padding: theme.spacing(1),
    "@media (max-width: 500px)": {
      marginLeft: 50,
      marginRight: 50
    }
  }
}))

export default function PopoverTokenGiven({open, anchorEl, handlePopoverClose, idIcon}) {
  const classes = useStyles()

  return (
    <Popover
      id="mouse-over-popover"
      style={{
        width: '100%'
      }}
      className={classes.popover}
      classes={{
        paper: (idIcon === 'total' ? 
          classes.paperTotal :
          (idIcon === 'activated' ?
            classes.paperActivated : classes.paperNotYet
          )
        )
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: (idIcon === 'notyet' ? 'right' : 'left'),
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      {idIcon === 'total' ?
      (
        <div>
          <Typography>Jumlah token yang dipunyai sekolah</Typography>
          <Typography style={{fontWeight: 'bold', color: 'rgba(250, 76, 6, 1)'}}>Penting !</Typography>
          <Typography>1. Mohon segera distribusi kode aktivasi ke para siswa</Typography>
          <Typography>2. Ingatkan para siswa untuk segera mengaktivasi tokennya</Typography>
        </div>
      ) :
      (idIcon === 'activated' ?
        <Typography>Jumlah token yang telah diaktivasi siswa</Typography> :
        <Typography>Jumlah token yang belum diaktivasi siswa</Typography>
      )}
    </Popover>
  )
}