const initialState = {
  pending: [],
  shown: false
}

export function tokenReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET/PENDING_TOKEN':
      return {...state, pending: action.payload}
    case 'SET/SHOWN_TOKEN':
      return {...state, shown: action.payload}
    default:
      return state
  }
}