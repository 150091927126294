import React from 'react'
import {Button, makeStyles} from '@material-ui/core'
import {AddInput} from '../index'

const useStyles = makeStyles((theme) => ({
  containerAddForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    borderRadius: 25,
    boxShadow: '0 3px 5px 2px rgba(145, 145, 145, .3)',
    height: 40,
    padding: '0 20px',
    margin: 8,
    fontSize: '20px',
    "@media (min-width: 1025px)": {
      transition: theme.transitions.create(
        ["transform", "box-shadow"],
        { duration: theme.transitions.duration.complex }
      ),
      "&:hover": {
        transform: 'scale(0.9)',
        boxShadow: 'none'
      },
    }
  }
}))

export default function AddTeacherForm({
  submit, lineForm, dataForm, changeValue, removeForm, grades, addLineForm
}) {
  const classes = useStyles()

  return (
    <form onSubmit={submit}>
      <div className={classes.containerAddForm}>
        {lineForm.map((data, index) => (
          <AddInput 
            key={index}
            data={data} 
            dataForm={dataForm} 
            changeValue={changeValue} 
            lineForm={lineForm} 
            removeForm={removeForm} 
            grades={grades} 
          />
        ))}
      </div>
      <div>
        <Button 
          style={{ backgroundColor: '#031269', color: 'white' }} 
          className={ classes.btn + " btnActive" } 
          onClick={ addLineForm }
          data-intro='Klik "Add Member" untuk menambah anggota tim yang akan mendapatkan akses Dashboard' 
          data-step="3"
        >
          + Input Lagi
        </Button>
        <Button 
          style={{ backgroundColor: 'rgb(250, 76, 6)', color: 'white' }} 
          className={ classes.btn + " btnActive" } 
          type="submit"
          data-intro='Simpan data guru yang baru saja diinput' data-step="4"
        >
          Kirim Data
        </Button>
      </div>
    </form>
  )
}