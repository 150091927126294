import React, { useEffect, useState } from 'react';
import {Divider, withStyles, makeStyles} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import MuiTableCell from "@material-ui/core/TableCell";
import { LoadingPage } from './index';
import Cookies from 'js-cookie';
import introJs from 'intro.js';
import { useHistory } from 'react-router-dom';
import {getTour, updateTour, getToken, getProfile, submitUpdateProfile, changePassword} from '../actions';
import {ProfileInformation, PersonalInformation, AccountInformation} from '../components'

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '2px',
    "@media (max-width: 768px)": {
      display: 'none'
    }
  },
  containerInput: {
    width: '80%',
    height: '88vh',
    paddingTop: '1vw',
    overflow: 'auto',
    "@media (max-width: 768px)": {
      width: '100%'
    }
  },
  subcontainerInput: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: '2vh',
    "@media (max-width: 600px)": {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}))

export default function ProfilePage() {
  const classes = useStyles()
  const profile = useSelector(state => state.profileReducer.profile);
  const loading = useSelector(state => state.profileReducer.loading);
  const dispatch = useDispatch();
  const [profileUpdated, setProfileUpdated] = useState(profile);
  const history = useHistory();
  const [password, setPassword] = useState({
    current: '',
    new: '',
    confirmed: ''
  });
  const tour = useSelector(state => state.tourReducer.status);

  useEffect(() => {
    if (profile) {
      setProfileUpdated(profile)
      dispatch({ type: 'UPDATE/LOADING_PROFILE', payload: false }) 
    }
  }, [profile])

  function intro() {
    // Untuk menjalankan fitur tour app ketika user membuka halaman tersebut utk yg pertama kali
    if (tour.profile === '0') {
      introJs().start();
      const Form = new FormData();
      Form.append('id_user', Cookies.get('id-mp'));
      Form.append('step', 'profile');
      dispatch(updateTour(Form));
    };
  }

  useEffect(() => {
    if (!loading) {
      intro()
    }
  }, [loading])

  useEffect(() => {
    // Cek apakah sudah login atau belum (bisa refactoring dengan menggunakan useContext)
    if (Cookies.get('cks-mp')) {
      dispatch(getToken());
      if (!tour) {
        dispatch(getTour());
      };
      if (!profile) {
        dispatch(getProfile(tour))
      }
    } else {
      history.push('/login');
    }
  }, [])

  function handleChange(e, category) {
    let temp = { ...profileUpdated }
    temp[category] = e.target.value
    setProfileUpdated(temp)
  }

  function updateProfile(e) {
    e.preventDefault();
    dispatch({ type: 'UPDATE/LOADING_PROFILE', payload: true })
    dispatch(submitUpdateProfile(profileUpdated, tour))
  }

  function handleChangePassword(e, category) {
    let temp = { ...password }
    temp[category] = e.target.value
    setPassword(temp)
  }

  function submitChangePassword(e) {
    dispatch({ type: 'UPDATE/LOADING_PROFILE', payload: true });
    e.preventDefault();
    dispatch(changePassword(password, setPassword))
  }

  if (loading || !profileUpdated) {
    return <LoadingPage />
  }

  return (
    <div className={"page mainpage row-up"}>
      <ProfileInformation profile={profile} />

      <Divider 
        orientation="vertical" 
        flexItem
        classes={{root: classes.divider}}
      />

      <div className={classes.containerInput}>
        <div className={classes.subcontainerInput}>
          <PersonalInformation 
            updateProfile={updateProfile} 
            profileUpdated={profileUpdated} 
            handleChange={handleChange} 
            profile={profile}
          />

          <Divider orientation="vertical" flexItem className={classes.divider} />

          <AccountInformation 
            profile={profile} 
            handleChangePassword={handleChangePassword} 
            password={password} 
            submitChangePassword={submitChangePassword}
          />

        </div>
      </div>
    </div>
  )
}