import React from 'react'
import {makeStyles, Typography} from '@material-ui/core'
import ReactPlayer from 'react-player'

const useStyles = makeStyles((theme) => ({
  videoItem: {
    margin: '20px', 
    height: '350px', 
    width: '350px',
    "@media (max-width: 450px)": {
      width: '400px',
      height: '330px'
    },
    "@media (max-width: 400px)": {
      height: '350px'
    },
    "@media (max-width: 350px)": {
      height: '330px'
    },
    "@media (max-width: 300px)": {
      height: '340px'
    }
  },
  containerPlayer: {
    height: '170px', 
    width: '350px',
    marginBottom: '10px',
    "@media (max-width: 360px)": {
      height: '140px', 
      width: '300px'
    },
    "@media (max-width: 280px)": {
      height: '100px', 
      width: '250px'
    }
  },
  containerText: {
    height: '40%', 
    width: '100%',
    "@media (max-width: 540px)": {
      width: '100%'
    }
  },
  videoText: {
    fontSize: '20px'
  },
  textDescription: {
    fontSize: '16px'
  }
}))

export default function LibraryVideoContent({content}) {
  const classes = useStyles()

  return (
    <div className={`${classes.videoItem} column-center`}>
      <div className={classes.containerPlayer}>
        <ReactPlayer 
          url={content.url} 
          style={{boxShadow: '3px 3px 10px 2px #D0D2C6'}}
          controls={true}
          width="100%"
          height="100%"
          playing
          light={content.thumbnail}
        />
      </div>
      <div className={classes.containerText}>
        <Typography className={classes.videoText} style={{fontWeight: 'bold'}} align="left">
          {content.title}
        </Typography>
        <Typography className={classes.textDescription} color="textSecondary" align="left">
          {content.description}
        </Typography>
        <Typography className={classes.textDescription} color="textSecondary" align="left">
          {content.time}
        </Typography>
      </div>
    </div>
  )
}