import React from 'react'
import {Typography, Table, TableBody, TableRow, TableCell, TextField, 
  Button, makeStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  containerForm: {
    padding: '1vw',
    "@media (max-width: 600px)": {
      marginBottom: '5vw'
    }
  },
  rightField: {
    width: '20vw',
    "@media (max-width: 768px)": {
      width: '25vw'
    },
    "@media (max-width: 600px)": {
      width: '60vw'
    }
  },
  btn: {
    backgroundColor: 'rgba(1, 61, 120, 1)',
    color: 'white',
    borderRadius: '25px',
    transition: theme.transitions.create(
      ["transform"],
      { duration: theme.transitions.duration.complex }
    ),
    "&:hover": {
      transform: 'scale(0.9)',
      backgroundColor: 'rgba(1, 61, 120, 0.75)'
    }
  }
}))

export default function AccountInformation({
  profile, handleChangePassword, password, submitChangePassword
}) {
  const classes = useStyles()

  return (
    <div className={`${classes.containerForm} column-up`}>
      <Typography
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          color: 'rgb(250, 76, 6)'
        }}
      >
        Data Akun
      </Typography>
      <form method="POST" onSubmit={submitChangePassword}>
        <Table style={{marginBottom: '1vw'}}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography style={{textAlign: 'right'}}>
                  <strong>Email:</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <TextField
                  disabled
                  className={classes.rightField}
                  value={profile.user_email}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography style={{textAlign: 'right'}}>
                  <strong>Password Sekarang:</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <TextField
                  required
                  className={classes.rightField}
                  type="password"
                  onChange={(e, category) => handleChangePassword(e, 'current')}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography style={{textAlign: 'right'}}>
                  <strong>Password Baru:</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <TextField
                  required
                  className={classes.rightField}
                  type="password"
                  onChange={(e, category) => handleChangePassword(e, 'new')}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography style={{textAlign: 'right'}}>
                  <strong>Konfirmasi Password Baru:</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <TextField
                  required
                  error={password.new !== password.confirmed && true}
                  className={classes.rightField}
                  type="password"
                  onChange={(e, category) => handleChangePassword(e, 'confirmed')}
                  helperText={password.new !== password.confirmed && "your confirmed password doesn't match"}
                />
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
        <Button variant="contained"
          type="submit"
          disabled={(password.new !== password.confirmed) && true}
          className={classes.btn}
          data-intro='Klik "Update Password" untuk menyimpan perubahan password' 
          data-step="2"
        >
          Simpan Password
        </Button>
      </form>
    </div>
  )
}