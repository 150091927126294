import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import {ImageLogin, FormLogin} from '../components'
import {submitLogin, getFinishAuth} from '../actions'
import {useDispatch} from 'react-redux'

const useStyles = makeStyles((theme) => ({
  page: {
    height: '100vh',
    width: '100vw',
    "@media (max-width: 900px)": {
      height: '90vh'
    }
  }
}))

export default function LoginPage() {
  const dispatch = useDispatch()
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [finishAuth, setfinishAuth] = useState(false);
  const [finishGetProfile, setfinishGetProfile] = useState(false);
  const [idUser, setidUser] = useState(null);

  useEffect(() => {
    // Cek apakah sudah login atau belum (bisa refactoring dengan menggunakan useContext)
    if (Cookies.get('cks-mp')) {
      history.push('/dashboard');
    };
  }, []);

  useEffect(() => {
    if (finishAuth && finishGetProfile) {
      history.push('/dashboard');
    }
  }, [finishAuth, finishGetProfile])

  useEffect(() => {
    if (finishAuth) {
      dispatch(getFinishAuth(idUser, setfinishGetProfile, setEmail, setPassword))
    }
  }, [finishAuth])

  function login() {
    if (email && password) {
      dispatch(submitLogin(email, password, setidUser, setfinishAuth, setEmail, setPassword))
    } else {
      swal("Oops!", "Tolong masukkan username / email dan password", "error");
    }
  }

  function handleClickShowPassword() {
    if (showPassword) {
      setShowPassword(false)
    } else {
      setShowPassword(true)
    }
  }

  return (
    <div className={classes.page + " row-center"}>
      <ImageLogin />
      <FormLogin
        email={email} 
        setEmail={setEmail} 
        showPassword={showPassword} 
        password={password} 
        setPassword={setPassword} 
        handleClickShowPassword={handleClickShowPassword} 
        login={login}
      />
    </div>
  )
}