import React from 'react'
import {TableRow, TableCell, makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    textAlign: 'center',
    fontSize: '16px',
    "@media (max-width: 500px)": {
      fontSize: '12px'
    }
  },
  fontBold: {
    fontWeight: 'bold'
  }
}))

export default function TableColumn({headerGroup}) {
  const classes = useStyles()

  return (
    <TableRow {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map((column, index) => (
        <TableCell 
          data-intro={index === 0 && 'Klik salah satu nama kolom untuk mengurutkan data berdasarkan kolom tersebut'} 
          data-step={index === 0 && "8"} 
          className={`${classes.alignCenter} ${classes.fontBold}`} 
          {...column.getHeaderProps(column.getSortByToggleProps())}
        >
          {column.render('Header')}
          <span>
            {column.isSorted
              ? column.isSortedDesc
                ? ' 🔽'
                : ' 🔼'
              : ''}
          </span>
        </TableCell>
      ))}
    </TableRow>
  )
}