import React from 'react'
import {FormControl, InputLabel, Select, makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    "@media (min-width: 769px)": {
      display: 'none'
    }
  }
}))

export default function BtnChooseClass({active, setActive, valueClass}) {
  const classes = useStyles()

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor="outlined-class-native-simple">
        Pilih Kelas
      </InputLabel>
      <Select
        native
        value={active}
        onChange={(e) => setActive(e.target.value)}
        label="Pilih Kelas"
        inputProps={{name: 'class', id: 'outlined-class-native-simple'}}
      >
        <option aria-label="Semua Kelas" value="all">
          Semua Kelas
        </option>
        {valueClass.map((data, index) => {
          return (
            data !== 'all' &&
            <option value={data} key={index}>
              {`Kelas ${data}`}
            </option>
          )
        })}
      </Select>
    </FormControl>
  )
}