const initialState = {
  status: null
}

export function tourReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET/TOUR':
      return { ...state, status: action.payload };
    default:
      return state;
  }
}