import {createStore, applyMiddleware, combineReducers} from 'redux'
import thunk from 'redux-thunk'
import { 
  eventReducer, 
  teacherReducer, 
  profileReducer, 
  summaryReducer,
  videoReducer,
  tourReducer,
  tokenReducer
} from './reducers'

const rootReducer = combineReducers({
  eventReducer,
  teacherReducer, 
  profileReducer,
  summaryReducer,
  videoReducer,
  tourReducer,
  tokenReducer
})

const store = createStore(rootReducer, applyMiddleware(thunk))


export default store