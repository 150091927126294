import React from 'react'
import {Step, StepLabel, StepContent, Typography, Paper, makeStyles} from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import {getStepContent, imgThumbnails} from '../../utils'
import {colors} from '../../styles'

const useStyles = makeStyles((theme) => ({
  paperImg: {
    height: '30%',
    minHeight: '120px',
    width: '50%', 
    minWidth: '250px',
    marginLeft: '16px',
    marginBottom: '10px',
    backgroundPosition: 'center', 
    backgroundSize: 'cover',
    boxShadow: '3px 3px 10px 2px #D0D2C6',
    cursor: 'pointer',
    "@media (max-width: 450px)": {
      minWidth: '170px',
      minHeight: '80px'
    },
    "@media (max-width: 350px)": {
      minWidth: '150px',
      minHeight: '70px'
    }
  },
  subcontainerImg: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(87, 87, 87, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerBtnPlay: {
    backgroundColor: 'rgba(255, 82, 0, 1)', 
    borderRadius: '50px', 
    border: '3px solid rgba(217, 217, 217, 1)',
    width: '40px', 
    height: '40px',
    transition: theme.transitions.create(
      ['transform'],
      {duration: theme.transitions.duration.complex}
    ),
    "&:hover": {
      transform: 'scale(0.9)'
    }
  },
  btnPlay: {
    color: 'white',
    width: '70px', 
    height: '40px',
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  disableText: {
    width: '50px',
    cursor: 'default',
    color: 'gray',
    marginRight: '10px'
  },
  btnText: {
    cursor: 'pointer',
    width: '50px',
    marginRight: '10px'
  },
  textBack: {
    fontWeight: 'bolder'
  },
  textNext: {
    fontWeight: 'bolder', 
    color: colors.orange
  }
}))

export default function ContentStepper({
  label, index, setshowVideo, activeStep, handleBack, handleNext, steps
}) {
  const classes = useStyles()

  return (
    <Step>
      <StepLabel>{label}</StepLabel>
      <StepContent>
        <Typography color="textSecondary" style={{marginBottom: '10px'}}>
          {getStepContent(index)}
        </Typography>
        <Paper 
          className={classes.paperImg} 
          onClick={() => setshowVideo(true)} 
          style={{backgroundImage: `url(${imgThumbnails[index]})`}}
        >
          <div className={classes.subcontainerImg}>
            <div className={`${classes.containerBtnPlay} row-center`}>
              <PlayArrowIcon className={classes.btnPlay} />
            </div>
          </div>
        </Paper>
        <div className={classes.actionsContainer}>
          <div className={'row-left'}>
            <div 
              onClick={activeStep !== 0 && handleBack} 
              className={
                `${activeStep === 0 ? classes.disableText : classes.btnText} row-center`
              }
            >
              <Typography className={classes.textBack}>
                Back
              </Typography>
            </div>
            <div onClick={handleNext} className={`${classes.btnText} row-center`}>
              <Typography className={classes.textNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Typography>
            </div>
          </div>
        </div>
      </StepContent>
    </Step>
  )
}