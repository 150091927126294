import React from 'react'
import {makeStyles} from '@material-ui/core'
import {ModulDescription, ModulButton} from '../index'

const useStyles = makeStyles((theme) => ({
  containerContent: {
    display: 'flex', 
    justifyContent: 'space-evenly', 
    alignItems: 'center', 
    padding: '2rem',
    "@media (max-width: 768px)": {
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  imgIcon: {
    "@media (max-width: 768px)": {
      display: 'none'
    }
  }
}))

export default function LibraryModul() {
  const classes = useStyles()

  return (
    <div className={classes.containerContent}>
      <img 
        src="https://maupintar.id/images/teacher/mtv-hi.png" 
        width="20%" 
        className={classes.imgIcon} 
        alt="mtv" 
      />
      <ModulDescription />
      <ModulButton />
    </div>
  )
}