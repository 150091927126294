import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import {NavLink, useLocation, useHistory} from 'react-router-dom'
import DashboardIcon from '@material-ui/icons/Dashboard'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import MenuBookIcon from '@material-ui/icons/MenuBook'
// import AllInboxIcon from '@material-ui/icons/AllInbox'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import Button from '@material-ui/core/Button'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import Cookies from 'js-cookie'
import {colors} from '../../styles'

const useStyles = makeStyles((theme) => ({
  list: {
    width: '5vw',
    height: '100vh',
    boxShadow: '2px 2px 2px 2px #D0D2C6',
    backgroundColor: colors.blue,
    "@media (max-width: 1024px)": {
      display: 'none'
    },
  },
  popover: {
    pointerEvents: 'none',
    marginTop: '-15px',
  },
  paper: {
    padding: theme.spacing(1),
  },
  icons: {
    color: 'white'
  },
  linkIcon: {
    textDecoration: 'none',
    margin: '3vh 0'
  }
}));

export default function SideNavbar({ intro }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [textAnchor, setTextAnchor] = useState('')
  const location = useLocation()
  const history = useHistory()

  const handleAnchorOpen = (event) => {
    setTextAnchor(event.currentTarget.title)
    setAnchorEl(event.currentTarget)
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const anchorOpen = Boolean(anchorEl)

  function goProfilePage() {
    history.push('/profile')
  }

  const active = {
    backgroundColor: '#FF5200',
    borderRadius: '25px',
    padding: '0.5vh 1vw'
  }

  const login = {
    display: 'none'
  }

  function logout() {
    Cookies.remove('id-mp');
    Cookies.remove('pic-mp');
    Cookies.remove('email-mp');
    Cookies.remove('cks-mp');
    Cookies.remove('rls-mp');
    history.push('/login');
  }

  return(
    <div className={classes.list} style={location.pathname === '/login' ? login : {}}>
      <List className={'column-up-down'} style={{height: '95vh'}}>
        <div className={'column-center'}>
          <NavLink exact to="/dashboard"
            activeStyle={active}
            className={classes.linkIcon}
            aria-owns={anchorOpen ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            title="Beranda"
            onMouseEnter={handleAnchorOpen}
            onMouseLeave={handleAnchorClose}
          >
            <DashboardIcon className={classes.icons} />
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{paper: classes.paper}}
              open={anchorOpen}
              anchorEl={anchorEl}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              transformOrigin={{vertical: 'center', horizontal: 'center'}}
              onClose={handleAnchorClose}
              disableRestoreFocus
            >
              <Typography>{textAnchor}</Typography>
            </Popover>
          </NavLink>

          <NavLink to="/video" 
            activeStyle={active}
            className={classes.linkIcon}
            aria-owns={anchorOpen ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            title="Mentari Tutor Video"
            onMouseEnter={handleAnchorOpen}
            onMouseLeave={handleAnchorClose}
          >
            <VideoLibraryIcon className={classes.icons} />
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{paper: classes.paper}}
              open={anchorOpen}
              anchorEl={anchorEl}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              transformOrigin={{vertical: 'center', horizontal: 'center'}}
              onClose={handleAnchorClose}
              disableRestoreFocus
            >
              <Typography>{textAnchor}</Typography>
            </Popover>
          </NavLink>

          <NavLink to="/belajar" 
            activeStyle={active}
            className={classes.linkIcon}
            aria-owns={anchorOpen ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            title="Ruang Pengembangan Diri"
            onMouseEnter={handleAnchorOpen}
            onMouseLeave={handleAnchorClose}
          >
            <MenuBookIcon className={classes.icons} />
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{paper: classes.paper}}
              open={anchorOpen}
              anchorEl={anchorEl}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              transformOrigin={{vertical: 'center', horizontal: 'center'}}
              onClose={handleAnchorClose}
              disableRestoreFocus
            >
              <Typography>{textAnchor}</Typography>
            </Popover>
          </NavLink>

          {/* <NavLink to="/bank-soal" 
            activeStyle={active}
            className={classes.linkIcon}
            aria-owns={anchorOpen ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            title="Bank Soal"
            onMouseEnter={handleAnchorOpen}
            onMouseLeave={handleAnchorClose}
          >
            <AllInboxIcon className={classes.icons} />
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{paper: classes.paper}}
              open={anchorOpen}
              anchorEl={anchorEl}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              transformOrigin={{vertical: 'center', horizontal: 'center'}}
              onClose={handleAnchorClose}
              disableRestoreFocus
            >
              <Typography>{textAnchor}</Typography>
            </Popover>
          </NavLink> */}

          {(Cookies.get('rls-mp') === 'administrator' || Cookies.get('rls-mp') === 'group_leader') &&
          (
            <NavLink to="/add-member" 
              activeStyle={active}
              className={classes.linkIcon}
              aria-owns={anchorOpen ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              title="Tambah Guru"
              onMouseEnter={handleAnchorOpen}
              onMouseLeave={handleAnchorClose}
            >
              <GroupAddIcon className={classes.icons} />
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{paper: classes.paper}}
                open={anchorOpen}
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                transformOrigin={{vertical: 'center', horizontal: 'center'}}
                onClose={handleAnchorClose}
                disableRestoreFocus
              >
                <Typography>{textAnchor}</Typography>
              </Popover>
            </NavLink>
          )}

          <NavLink to="/library" 
            activeStyle={active}
            className={classes.linkIcon}
            aria-owns={anchorOpen ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            title="Pustaka"
            onMouseEnter={handleAnchorOpen}
            style={{textDecoration: 'none'}}
            onMouseLeave={handleAnchorClose}
          >
            <LocalLibraryIcon className={classes.icons} />
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{paper: classes.paper}}
              open={anchorOpen}
              anchorEl={anchorEl}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              transformOrigin={{vertical: 'center', horizontal: 'center'}}
              onClose={handleAnchorClose}
              disableRestoreFocus
            >
              <Typography>{textAnchor}</Typography>
            </Popover>
          </NavLink>
        </div>
        <div className={'column-center'}>
          <Button
            className={classes.linkIcon}
            aria-owns={anchorOpen ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            title="Profil"
            onMouseEnter={handleAnchorOpen}
            onMouseLeave={handleAnchorClose}
            onClick={goProfilePage}
            disableFocusRipple
          >
            <Avatar src={Cookies.get('pic-mp')} />
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{paper: classes.paper}}
              open={anchorOpen}
              anchorEl={anchorEl}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              transformOrigin={{vertical: 'center', horizontal: 'center'}}
              onClose={handleAnchorClose}
              disableRestoreFocus
            >
              <Typography>{textAnchor}</Typography>
            </Popover>
          </Button>

          <Button
            className={classes.linkIcon}
            aria-owns={anchorOpen ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            title="Keluar"
            onMouseEnter={handleAnchorOpen}
            onMouseLeave={handleAnchorClose}
            disableFocusRipple
            onClick={logout}
          >
            <PowerSettingsNewIcon
              className={`${classes.icons} ${classes.linkIcon}`}
              style={{margin: '0'}}
            />
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{paper: classes.paper}}
              open={anchorOpen}
              anchorEl={anchorEl}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              transformOrigin={{vertical: 'center', horizontal: 'center'}}
              onClose={handleAnchorClose}
              disableRestoreFocus
            >
              <Typography>{textAnchor}</Typography>
            </Popover>
          </Button>
        </div>
      </List>
    </div>
  )
}
