import React from 'react'
import {CSVLink} from 'react-csv'
import {Button, Typography, makeStyles} from '@material-ui/core'
import {colors, fontSize} from '../../styles'

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: colors.green, 
    transition: theme.transitions.create(
      ["margin-bottom", "margin-top", "box-shadow"],
      {duration: theme.transitions.duration.complex}
    ),
    "&:hover": {
      backgroundColor: colors.green,
      marginBottom: '5px',
      marginTop: '-5px',
      boxShadow: '5px 5px 10px 2px #D0D2C6'
    }
  }
}))

export default function DownloadCSV({data}) {
  const classes = useStyles()
  const headers = [
    {label: 'Token', key: 'col1'},
    {label: 'Kelas', key: 'col2'}
  ]

  return (
    <CSVLink 
      data={data} 
      headers={headers}
      filename={"token-unactivated.xlsx"}
      style={{textDecoration: 'none', marginBottom: '10px'}}
    >
      <Button className={classes.btn}>
        <Typography 
          variant="h6" 
          style={{
            color: 'white', 
            fontSize: fontSize.textBtn, 
            fontWeight: 'bold'
          }}
        >
          Download Excel
        </Typography>
      </Button>
    </CSVLink>
  )
}