import React from 'react'
import {Button, makeStyles} from '@material-ui/core'
import {colors, shadowCard, fontSize} from '../../styles'

const useStyles = makeStyles((theme) => ({
  pills: {
    "@media (max-width: 768px)": {
      display: 'none'
    }
  },
  btn: {
    border: 0,
    borderRadius: 25,
    boxShadow: shadowCard.orangeShadow,
    height: 40,
    padding: '0 2vw',
    margin: 8,
    color: colors.lightOrange,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: colors.lightOrange,
    fontSize: fontSize.textBtn,
    "@media (max-width: 767px)": {
      padding: '0 5vw'
    },
    "@media (max-width: 500px)": {
      padding: '0 7vw'
    },
    "@media (min-width: 1025px)": {
      transition: theme.transitions.create(
        ['transform', 'box-shadow'],
        { duration: theme.transitions.duration.complex }
      ),
      "&:hover": {
        transform: 'scale(0.9)',
        boxShadow: 'none'
      } 
    }
  }
}))

export default function BtnClassCategory({value, active, setActive, activeButton}) {
  const classes = useStyles()

  return (
    <Button 
      style={
        value === active ? 
        activeButton : 
        {backgroundColor: 'white'}
      } 
      className={classes.btn + " btnActive " + classes.pills} 
      onClick={() => setActive(value)}
    >
      {value === 'all' ?
      ('Semua Kelas') :
      ('Kelas ' + value)}
    </Button>
  )
}