import axios from 'axios';

export function getMTVCourses(form) {
  return (dispatch, getState) => {
    axios({
      baseURL: 'https://maupintar.id/app_api',
      url: '/get_my_mtv_vod.php',
      method: 'POST',
      data: form
    })
      .then(response => {
        const output = response.data.filter(value => {
          return value.type === 'mtv';
        });
        output.sort(function (a, b) {
          return a.course_id - b.course_id
        })
        dispatch(getMyMTV(output));
      })
      .catch(error => {
        console.log(error, 'error get mtv courses')
      })
  }
}

export function getMTVDetail(form) {
  return (dispatch, getState) => {
    axios({
      baseURL: 'https://maupintar.id/app_api',
      url: '/get_mtv_course_content.php',
      method: 'POST',
      data: form,
    })
      .then(response => {
        dispatch({ type: 'GET/MTV_CONTENT', payload: response.data })
        dispatch({ type: 'UPDATE/LOADING_MTV_CONTENT', payload: false })
        dispatch({ type: 'UPDATE/LOADING_OPEN_MTV_CONTENT', payload: false })
      })
      .catch(error => {
        console.log(error, 'error detail mtv courses');
      })
  }
}

export function getSubTopics(form) {
  return (dispatch, getState) => {
    axios({
      baseURL: 'https://maupintar.id/app_api',
      url: '/get_mtv_strand_lessons.php',
      method: 'POST',
      data: form,
    })
      .then(response => {
        if (response.data.lessons) {
          dispatch({ type: 'GET/SUBTOPIC', payload: response.data.lessons })
        } else {
          dispatch({ type: 'GET/SUBTOPIC', payload: [] })
        }
      })
      .catch(error => {
        console.log(error, 'error get sub topics');
      })
  }
}

export function getMyMTV(data) {
  return (dispatch, getState) => {
    axios({
      url: 'https://maupintar.id/app_api/get_mtv_courses.php',
      method: 'POST'
    })
      .then(response => {
        let output = [];
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < response.data.length; j++) {
            if (data[i].course_id === response.data[j].id) {
              output.push(response.data[j]);
            };
          };
        };
        dispatch({ type: 'GET/VIDEOS', payload: output })
        dispatch({ type: 'UPDATE/LOADING_VIDEO', payload: false })
      })
      .catch(error => {
        console.log('error get my MTV >>>> ', error);
      })
  }
}
