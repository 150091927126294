import axios from 'axios'
import Cookies from 'js-cookie'

export function getEvents() {
  return (dispatch, getState) => {
    axios({
      method: 'GET',
      url: 'https://maupintar.id/app_api/get_hy_event_list.php'
    })
      .then(response => {
        dispatch({ type: 'GET/EVENTS', payload: response.data })
        dispatch({ type: 'UPDATE/LOADING_EVENT', payload: false })
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export function getModuls(Form) {
  return (dispatch, getState) => {
    axios({
      method: 'POST',
      url: 'https://maupintar.id/app_api/get_vod_list.php',
      data: Form
    })
      .then(response => {
        let output = []
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].subscribed) {
            output.push(response.data[i])
          }
        }
        dispatch({ type: 'GET/MODULS', payload: output })
        dispatch({ type: 'UPDATE/LOADING_MODUL', payload: false })
      })
      .catch(error => {
        console.log('error get moduls >>>> ', error)
      })
  }
}

export function getModulDetail(course_id, lesson_id, setModulDetail) {
  return (dispatch, getState) => {
    const Form = new FormData();
    Form.append('course_id', course_id);
    Form.append('id_user', Cookies.get('id-mp'));
    Form.append('lesson_id', lesson_id);

    axios({
      method: 'POST',
      url: 'https://maupintar.id/app_api/get_vod_lessons_content.php',
      data: Form
    })
    .then(response => {
      setModulDetail(response.data[0])
    })
    .catch(error => {
      console.log('error get modul detail >>>> ', error)
    })
  }
}
