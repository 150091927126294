import axios from 'axios';
import Cookies from 'js-cookie';
import { getTour } from './index';
import swal from 'sweetalert'

export function getProfile(tour) {
  return (dispatch, getState) => {
    const Form = new FormData();
    Form.append('id_user', Cookies.get('id-mp'));

    axios({
      url: 'https://maupintar.id/app_api/get_user_profile.php',
      method: 'POST',
      data: Form
    })
    .then(response => {
      dispatch({ type: 'GET/PROFILE', payload: response.data[0] })
      if (!tour) {
        dispatch(getTour())
      }
    })
    .catch(error => {
      console.log('error get user ID >>>> ', error);
    })
  }
}

export function submitUpdateProfile(profileUpdated, tour) {
  return (dispatch, getState) => {
    const Form = new FormData();
    Form.append('id_user', profileUpdated.id_user)
    Form.append('first_name', profileUpdated.student_name)
    Form.append('last_name', '')
    Form.append('phone_number', profileUpdated.student_phone)
    Form.append('kota_asal', profileUpdated.homecity)
    Form.append('role', profileUpdated.role)
    Form.append('referrer_user_id', profileUpdated.referrer_user_id)
    Form.append('parent_name', profileUpdated.parent_name)
    Form.append('student_name', profileUpdated.student_name)
    Form.append('school_name', profileUpdated.school)
    Form.append('kelas', '')
    Form.append('action', 'update')
  
    axios({
      method: 'POST',
      url: 'https://maupintar.id/app_api/update_user_info.php',
      data: Form
    })
      .then(response => {
        dispatch(getProfile(tour))
      })
      .catch(error => {
        console.log('error update profile >>>> ', error);
      })
  }
}

export function changePassword(password, setPassword) {
  return (dispatch, getState) => {
    const Form = new FormData();
    Form.append('id_user', Cookies.get('id-mp'));
    Form.append('password', password.current);
    Form.append('new_password', password.new);

    axios({
      method: 'POST',
      url: 'https://maupintar.id/app_api/update_user_password.php',
      data: Form
    })
    .then(response => {
      dispatch({ type: 'UPDATE/LOADING_PROFILE', payload: false })
      if (response.data.status) {
        swal({
          title: "Selamat!",
          text: "Password Anda telah diupdate!",
          icon: "success",
          button: "OK",
        })
      } else {
        swal({
          title: "Oops!",
          text: response.data.msg,
          icon: "error",
          button: "OK",
        })
      }
    })
    .catch(error => {
      console.log('error ganti password >>>> ', error)
    })
    .finally(() => {
      setPassword({
        current: '',
        new: '',
        confirmed: ''
      })
    })
  }
}