import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import {Provider} from 'react-redux'
import store from './store/index'
import {makeStyles, createMuiTheme, ThemeProvider} from '@material-ui/core/styles'
import Cookies from 'js-cookie'
import {LoginPage, AddMember, FAQPage,
  LearningPage, ModulDetail, MTVDetail, ProfilePage,
  TopicPage, VideoPage, RuangBelajarPage,
  DashboardPage, QuizPage, LibraryPage
} from './pages'
import {SideNavbar, TopNavbar} from './components'
import React from 'react'

const font =  "'Poppins', sans-serif"

const useStyles = makeStyles((theme) => ({
  mainPage: {
    display: 'flex'
  }
}))

const THEME = createMuiTheme({
  typography: {
    fontFamily: font,
    button: {
      textTransform: 'none'
    }
  }
})


function App() {
  const classes = useStyles()

  return (
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <Router>
          <div className="App">
            <div className={classes.mainPage}>
              <SideNavbar/>
              <div>
                <TopNavbar></TopNavbar>
                <Switch>
                  <Route path="/login">
                    <LoginPage></LoginPage>
                  </Route>
                  <Route path="/dashboard">
                    <DashboardPage></DashboardPage>
                  </Route>
                  <Route path="/video/:course_id/:lesson_id/:quiz_id">
                    <QuizPage></QuizPage>
                  </Route>
                  <Route path="/video/:course_id/:lesson_id">
                    <LearningPage></LearningPage>
                  </Route>
                  <Route path="/video/:mtv_id">
                    <MTVDetail></MTVDetail>
                  </Route>
                  <Route path="/video">
                    <VideoPage></VideoPage>
                  </Route>
                  <Route path="/belajar/:course_id/:lesson_id">
                    <ModulDetail></ModulDetail>
                  </Route>
                  <Route path="/belajar">
                    <RuangBelajarPage></RuangBelajarPage>
                  </Route>
                  <Route path="/topics">
                    <TopicPage></TopicPage>
                  </Route>
                  <Route path="/profile">
                    <ProfilePage></ProfilePage>
                  </Route>
                  <Route path="/add-member">
                    <AddMember></AddMember>
                  </Route>
                  <Route path="/faq">
                    <FAQPage></FAQPage>
                  </Route>
                  <Route path="/library">
                    <LibraryPage></LibraryPage>
                  </Route>
                  <Route path="/">
                    {Cookies.get('cks-mp') ?
                    (
                      <Redirect to="/dashboard" />
                    ) :
                    (
                      <Redirect to="/login" />
                    )}
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
