import React, { useState, useEffect } from 'react';
import { Card, makeStyles } from '@material-ui/core';
import { TokenGivenContent } from '../index';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '30vw',
    height: '20vh',
    backgroundColor: 'rgba(255, 222, 209, 1)',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '0 2vw',
    borderRadius: '20px',
    boxShadow: '3px 3px 10px 2px #D0D2C6',
    margin: '2vh',
    minWidth: '90px',
    "@media (max-width: 1024px)": {
      width: '40vw',
      height: '10vh'
    },
    "@media (max-width: 765px)": {
      width: '90vw',
      height: '15vh'
    },
    "@media (max-width: 540px)": {
      width: '70vw',
      height: '80px',
      margin: '10px'
    }
  }
}))

export default function TokenGiven({
  intro, notYet, activatedToken
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [idIcon, setIdIcon] = useState('')

  const handlePopoverOpen = (event) => {
    setIdIcon(event.currentTarget.id)
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    intro()
  }, [])

  return (
    <Card className={classes.card}>
      {['total', 'activated', 'notyet'].map((value, index) => {
        return (
          <TokenGivenContent 
            id={value}
            handlePopoverClose={handlePopoverClose}
            handlePopoverOpen={handlePopoverOpen}
            anchorEl={anchorEl}
            notYet={notYet}
            activatedToken={activatedToken}
            open={open}
            idIcon={idIcon}
            key={index}
          />
        )
      })}
    </Card>
  )
}