const initialState = {
  loadingEvent: true,
  loadingModul: true,
  events: [],
  moduls: []
}

export function eventReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET/EVENTS':
      return { ...state, events: action.payload };
    case 'UPDATE/LOADING_EVENT':
      return { ...state, loadingEvent: action.payload };
    case 'GET/MODULS':
      return { ...state, moduls: action.payload };
    case 'UPDATE/LOADING_MODUL':
      return { ...state, loadingModul: action.payload };
    default:
      return state;
  }
}