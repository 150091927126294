import React from 'react'
import {FormControl, InputLabel, Select, makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}))

export default function SelectClass({classSelected, setClassSelected, classProfile}) {
  const classes = useStyles()
  return (
    <FormControl 
      variant="outlined" 
      className={classes.formControl} 
      data-intro='Pilih Kelas yang ingin Anda akses' 
      data-step="1"
    >
      <InputLabel htmlFor="outlined-class-native-simple">
        Pilih Kelas
      </InputLabel>
      <Select
        native
        value={classSelected}
        onChange={(e) => setClassSelected(e.target.value)}
        label="Pilih Kelas"
        inputProps={{name: 'class', id: 'outlined-class-native-simple'}}
      >
        <option aria-label="Semua Kelas" value="all">
          Semua Kelas
        </option>
        {classProfile.map((data, index) => {
          return (
            <option value={data} key={index}>{`Kelas ${data}`}</option>
          )
        })}
      </Select>
    </FormControl>
  )
}