import React from 'react'
import {Modal, Fade, Button, Typography, makeStyles, Backdrop} from '@material-ui/core'
import parse from 'html-react-parser'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
    padding: theme.spacing(2, 4, 3),
    maxHeight: '90%',
    overflow: 'scroll'
  },
  btnSubmit: {
    backgroundColor: 'rgb(3,172,14)', 
    borderRadius: '20px', 
    padding: '0.5rem 2rem',
    transition: theme.transitions.create(
      ["transform", "background-color"],
      { duration: theme.transitions.duration.complex }
    ),
    "&:hover": {
      transform: 'scale(1.2)',
      backgroundColor: 'rgb(3,172,14)'
    }
  }
}))

export default function ModalQuizResult({showExplanation, closeExplanation, quiz, current}) {
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={"row-center modal"}
      open={showExplanation}
      onClose={closeExplanation}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showExplanation}>
        <div className={`${classes.paper} column-center`}>
            {parse(quiz[current]._correctMsg)}
            <Button 
              className={classes.btnSubmit} 
              style={{marginTop: '2rem'}} 
              onClick={closeExplanation}
            >
              <Typography style={{fontWeight: 'bold', color: 'white'}}>
                OK
              </Typography>
            </Button>
        </div>
      </Fade>
    </Modal>
  )
}