import React from 'react'
import {makeStyles, Typography, FormControl, InputLabel, Input, 
  InputAdornment, Button, IconButton
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme) => ({
  formLogin: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 1)'
  },
  columnCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    "@media (max-width: 1024px)": {
      justifyContent: 'flex-start',
      paddingTop: '20vh'
    }
  },
  logo: {
    width: '45%',
    margin: '2vh 1vw',
    "@media (max-width: 1024px)": {
      width: '25%'
    },
    "@media (max-width: 900px)": {
      width: '50%'
    }
  },
  openingWords: {
    width: '35vw', 
    margin: '1vw 0',
    "@media (max-width: 900px)": {
      width: '80vw'
    }
  },
  colorOpeningWords: {
    color: 'rgba(51, 51, 51, 0.75)'
  },
  titleOpening: {
    fontSize: '1.75vw',
    "@media (max-width: 900px)": {
      fontSize: '5vw'
    }
  },
  subtitleOpening: {
    fontSize: '1.5vw',
    "@media (max-width: 900px)": {
      fontSize: '4.5vw'
    }
  },
  margin: {
    margin: theme.spacing(1),
    width: '35vw',
    "@media (max-width: 900px)": {
      width: '80vw'
    }
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: '25px',
      borderStyle: 'none',
      backgroundColor: 'lightgrey',
      height: '7vh'
    }
  },
  btn: {
    borderRadius: '30px',
    width: '35vw',
    padding: '5px 0',
    fontSize: '30px',
    "@media (max-width: 900px)": {
      width: '80vw',
      fontSize: '20px'
    }
  }
}))

export default function FormLogin({
  email, setEmail, showPassword, password, setPassword, handleClickShowPassword, login
}) {
  const classes = useStyles()

  function pressEnter(e) {
    if (e.key === 'Enter') {
      login()
    }
  }

  return (
    <div className={classes.formLogin + " " + classes.columnCenter}>
      <img 
        src="https://maupintar.id/images/teacher/logo-maupintar.png"
        className={classes.logo}
        alt="logo maupintar"
      />
      <div className={classes.openingWords}>
        <Typography 
          align="left" 
          className={classes.colorOpeningWords + ' ' + classes.titleOpening} 
          style={{fontWeight: 'bold'}}
        >
          Selamat datang di Maupintar!
        </Typography>
        <Typography 
          align="left" 
          className={classes.colorOpeningWords + ' ' + classes.subtitleOpening}
        >
          Sebuah ekosistem pembelajaran untuk para Siswa, Guru, dan Orangtua.
        </Typography>
      </div>
      <div
        style={{
          margin: '20px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <FormControl className={classes.margin + ' ' + classes.textField}>
          <InputLabel htmlFor="standard-email" className={classes.subtitleOpening}>
            Email
          </InputLabel>
          <Input
            id="standard-email"
            type={'email'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={(e) => pressEnter(e)}
            className={classes.subtitleOpening}
          />
        </FormControl>

        <FormControl className={classes.margin + ' ' + classes.textField}>
          <InputLabel htmlFor="standard-adornment-password" className={classes.subtitleOpening}>
            Password
          </InputLabel>
          <Input
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            onKeyPress={(e) => pressEnter(e)}
            className={classes.subtitleOpening}
          />
        </FormControl>
      </div>
      <Button variant="contained" color="primary"
        className={classes.btn}
        onClick={login}
        disableFocusRipple
      >
        <Typography 
          align="center" 
          className={classes.titleOpening} 
          style={{fontWeight: 'bold'}}
        >
          Masuk
        </Typography>
      </Button>
    </div>
  )
}