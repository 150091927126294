export const videoLinks = [
  'https://maupintar.id/documents/dashboard-teacher/opening-video.mp4',
  'https://maupintar.id/documents/dashboard-teacher/video2.m4v',
  'https://maupintar.id/documents/dashboard-teacher/video3.mp4',
  'https://maupintar.id/documents/dashboard-teacher/video4.m4v',
  'https://maupintar.id/documents/dashboard-teacher/video5.m4v',
  'https://maupintar.id/documents/dashboard-teacher/video6.m4v',
  'https://maupintar.id/documents/dashboard-teacher/video7.mp4'
]

export const imgThumbnails = [
  'https://maupintar.id/documents/dashboard-teacher/thumbnail1.png',
  'https://maupintar.id/documents/dashboard-teacher/thumbnail2.png',
  'https://maupintar.id/documents/dashboard-teacher/thumbnail3.png',
  'https://maupintar.id/documents/dashboard-teacher/thumbnail4.png',
  'https://maupintar.id/documents/dashboard-teacher/thumbnail5.png',
  'https://maupintar.id/documents/dashboard-teacher/thumbnail6.png',
  'https://maupintar.id/documents/dashboard-teacher/thumbnail7.png'
]

export const listColumns = [
  {
    Header: "Nama Siswa",
    accessor: 'name'
  },
  {
    Header: "Kelas",
    accessor: 'grade'
  },
  {
    Header: 'Jumlah Video',
    accessor: 'qtyVideo'
  },
  {
    Header: '% Video',
    accessor: 'percVideo'
  },
  {
    Header: 'Jumlah Kuis',
    accessor: 'qtyQuiz'
  },
  {
    Header: '% Kuis',
    accessor: 'percQuiz'
  },
  {
    Header: 'Point',
    accessor: 'point'
  }
]

export const libraryVideos = [
  {
    url: 'https://maupintar.id/documents/dashboard-teacher/opening-video.mp4',
    thumbnail: 'https://maupintar.id/documents/dashboard-teacher/thumbnail1.png',
    title: 'Pengantar dari COO Maupintar',
    description: 'Selamat Datang di Ekosistem Maupintar!',
    time: '(1:49)'
  },
  {
    url: 'https://maupintar.id/documents/dashboard-teacher/video2.m4v',
    thumbnail: 'https://maupintar.id/documents/dashboard-teacher/thumbnail2.png',
    title: '4 Langkah Memulai Ekosistem Maupintar',
    description: '4 hal penting yang perlu dilakukan sebelum mulai menggunakan Ekosistem Maupintar!',
    time: '(1:51)'
  },
  {
    url: 'https://maupintar.id/documents/dashboard-teacher/video3.mp4',
    thumbnail: 'https://maupintar.id/documents/dashboard-teacher/thumbnail3.png',
    title: 'Perkenalkan, Mentari Tutor Video (MTV)!',
    description: 'Apa itu MTV? Bagaimana MTV bisa membantu siswa dan guru selama proses belajar mengajar?',
    time: '(1:51)'
  },
  {
    url: 'https://maupintar.id/documents/dashboard-teacher/video4.m4v',
    thumbnail: 'https://maupintar.id/documents/dashboard-teacher/thumbnail4.png',
    title: 'MTV: Sebuah Pendekatan "Blended Learning" dalam Ruang Ajar',
    description: 'Bagaimana MTV bisa membantu pengajaran dengan pendekatan Blended Learning?',
    time: '(3:56)'
  },
  {
    url: 'https://maupintar.id/documents/dashboard-teacher/video5.m4v',
    thumbnail: 'https://maupintar.id/documents/dashboard-teacher/thumbnail5.png',
    title: 'Video Tutorial #1 : Mengenal Dasbhoard Guru di Maupintar',
    description: '',
    time: ''
  },
  {
    url: 'https://maupintar.id/documents/dashboard-teacher/video6.m4v',
    thumbnail: 'https://maupintar.id/documents/dashboard-teacher/thumbnail6.png',
    title: 'Video Tutorial #2 : Mengenal Fitur Halaman MTV di Dashboard Guru',
    description: '',
    time: ''
  },
  {
    url: 'https://maupintar.id/documents/dashboard-teacher/video7.mp4',
    thumbnail: 'https://maupintar.id/documents/dashboard-teacher/thumbnail7.png',
    title: 'Video Tutorial #3 : Profesional Development dalam Halaman Pengembangan Diri',
    description: '',
    time: ''
  }
]
