import React from 'react'
import {TextField, makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  textInput: {
    width: '25vw',
    minWidth: '250px'
  },
  input: {
    fontSize: 14
  },
  labelRoot: {
    fontSize: 14,
    textAlign: 'left'
  }
}))

export default function InputEmail({data, dataForm, changeValue}) {
  const classes = useStyles()

  return (
    <TextField 
      id={data} 
      label="Email (contoh: tirto@mail.com)"
      type="email"
      className={classes.textInput}
      inputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        classes: {
          root: classes.labelRoot
        }
      }}
      value={(dataForm.filter(email => (
          email.id === data && email.email
        ))[0]) ?
        (
          dataForm.filter(email => (
            email.id === data && email.email
          ))[0].email
        ) : null}
      onChange={(e, id, key) => changeValue(e, data, 'email')}
      required
    />
  )
}