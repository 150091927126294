import React, { useState, useEffect } from 'react'
import { Typography, Button, makeStyles} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LoadingPage } from './index'
import Cookies from 'js-cookie'
import introJs from 'intro.js'
import { getTour, updateTour, getToken, getEvents, getModuls } from '../actions'
import {shadowCard, colors, fontSize} from '../styles'
import {CardVideoInspiratif, EmptyVideoInspiratif, CardEvent, EmptyEvent} from '../components'

const useStyles = makeStyles((theme) => ({
  btn: {
    border: 0,
    borderRadius: 25,
    boxShadow: shadowCard.orangeShadow,
    height: 40,
    padding: '0 15px',
    margin: '10px',
    color: colors.lightOrange,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: colors.lightOrange,
    fontSize: fontSize.textBtn,
    "@media (max-width: 768px)": {
      fontSize: '16px',
      padding: '0 5vw',
      boxShadow: 'none'
    },
    "@media (max-width: 500px)": {
      padding: '0 7vw'
    },
    "@media (min-width: 1025px)": {
      transition: theme.transitions.create(
        ["transform", "box-shadow"],
        { duration: theme.transitions.duration.complex }
      ),
      "&:hover": {
        transform: 'scale(0.9)',
        boxShadow: 'none'
      }
    }
  },
  containerStatement: {
    margin: '1rem 10rem',
    "@media (max-width: 768px)": {
      margin: '1rem 3rem'
    }
  },
  titleStatement: {
    fontWeight: 'bold', 
    margin: '1vw', 
    fontSize: '30px',
    "@media (max-width: 500px)": {
      fontSize: '20px'
    },
    "@media (max-width: 350px)": {
      fontSize: '18px'
    }
  },
  subtitleStatement: {
    textAlign: 'justify', 
    fontSize: '20px',
    "@media (max-width: 500px)": {
      fontSize: '16px',
      textAlign: 'left'
    },
    "@media (max-width: 350px)": {
      fontSize: '14px'
    }
  }
}))

export default function RuangBelajarPage() {
  const classes = useStyles();
  const category = ['Video Inspiratif', 'Seminar Inspiratif'];
  const [active, setActive] = useState('Video Inspiratif');
  const events = useSelector(state => state.eventReducer.events);
  const loadingEvent = useSelector(state => state.eventReducer.loadingEvent);
  const videos = useSelector(state => state.eventReducer.moduls);
  const loadingVideo = useSelector(state => state.eventReducer.loadingModul);
  const history = useHistory();
  const dispatch = useDispatch();
  const tour = useSelector(state => state.tourReducer.status);

  function getModulDetail(data) {
    history.push(`/belajar/${data.id}/${data.lessons_list[0].id}`)
  }

  const activeButton = {
    backgroundColor: 'rgb(250, 76, 6)',
    color: 'white'
  }

  useEffect(() => {
    // Cek apakah sudah login atau belum (bisa refactoring dengan menggunakan useContext)
    if (Cookies.get('cks-mp')) {
      dispatch(getToken());
      if (!tour) {
        dispatch(getTour());
      };
      const Form = new FormData();
      Form.append('id_user', Cookies.get('id-mp'));
      Form.append('dashboard', true);
      dispatch(getEvents());
      dispatch(getModuls(Form));
      return () => {
        dispatch({ type: 'UPDATE/LOADING_EVENT', payload: true })
        dispatch({ type: 'UPDATE/LOADING_MODUL', payload: true })
      }
    } else {
      history.push('/login');
    }
  }, [])

  function intro() {
    // Untuk menjalankan fitur tour app ketika user membuka halaman tersebut utk yg pertama kali
    if (tour.belajar === '0') {
      introJs().start();
      const Form = new FormData();
      Form.append('id_user', Cookies.get('id-mp'));
      Form.append('step', 'belajar');
      dispatch(updateTour(Form));
    };
  }

  useEffect(() => {
    if (!loadingEvent && !loadingVideo) {
      intro()
    }
  }, [loadingEvent, loadingVideo])

  if (loadingEvent || loadingVideo) {
    return <LoadingPage></LoadingPage>
  }

  return (
    <div className={"page mainpage"}>
      <div className={classes.containerStatement}>
        <Typography className={classes.titleStatement}>
          Ruang Pengembangan Diri
        </Typography>
        <Typography color="textSecondary" className={classes.subtitleStatement}>
          Ruang Pengembangan Diri menghadirkan modul-modul pembelajaran bagi para pendidik 
          agar dapat meningkatkan produktivitas dan kapasitas Anda sebagai pengajar. 
          Anda akan mendapatkan inspirasi baik melalui video-video pembelajaran atau mengikuti 
          seminar/workshop pendidikan yang tersedia
        </Typography>
      </div>
      <div 
        data-intro='Silahkan pilih Video Inspiratif atau Seminar Inspiratif untuk "Professional Development"' 
        data-step="1"
      >
        {category.map((value, index) => {
          return (
          <Button 
            style={value === active ? activeButton : {backgroundColor: 'white'}} 
            className={classes.btn + " btnActive"} 
            onClick={() => setActive(value)} 
            key={index}
          >
            {value}
          </Button>)
        })}
      </div>
      <div className={'row-center'} style={{flexWrap: 'wrap'}}>
        {active === 'Video Inspiratif' ?
          (videos.length > 0 ?
            (
              videos.map((video, index) => {
                return (
                  <CardVideoInspiratif 
                    key={index}
                    getModulDetail={getModulDetail} 
                    video={video} 
                    activeButton={activeButton}
                  />
                )
              })
            ) :
            <EmptyVideoInspiratif />
          ) :
          (events.length > 0 ?
            (
              events.map((event, index) => {
                return (
                  <CardEvent 
                    key={index}
                    event={event} 
                    activeButton={activeButton}
                  />
                )
              })
            ) :
            <EmptyEvent />
          )
        }
      </div>
    </div>
  )
}