import React from 'react'
import {makeStyles, Typography} from '@material-ui/core'
import { PopoverTokenGiven } from '../index'

const useStyles = makeStyles((theme) => ({
  cardStat: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '70%',
    cursor: 'pointer',
    transition: theme.transitions.create(
      ["transform"],
      { duration: theme.transitions.duration.complex }
    ),
    "&:hover": {
      transform: 'scale(1.2)'
    }
  },
  titleStat: {
    color: 'rgb(61, 61, 61)',
    fontWeight: 'bold',
    fontSize: '17px',
    "@media (max-width: 768px)": {
      fontSize: '14px'
    },
    "@media (max-width: 540px)": {
      fontSize: '12px'
    }
  },
  countStat: {
    fontWeight: 'bold',
    color: 'rgba(250, 76, 6, 1)', 
    fontSize: '45px',
    "@media (max-width: 540px)": {
      fontSize: '30px'
    }
  },
  completed: {
    color: 'rgba(3, 172, 14, 1)',
  },
}))

export default function TokenGivenContent({
  id, handlePopoverClose, handlePopoverOpen, anchorEl, notYet, activatedToken, open, idIcon
}) {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.cardStat}
        data-intro='Jumlah Total Token MTV yang dimiliki Sekolah Anda di level ini' data-step="5"
        id={id}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Typography className={classes.titleStat}>
          {id === 'total' ? 
            'Total Token' :
            (id === 'activated' ?
              'Teraktivasi' : 'Sisa Token'
            )
          }
        </Typography>
        <Typography 
          variant="h3" 
          className={classes.countStat + ' ' + ((notYet === 0 && activatedToken > 0) && classes.completed)}
        >
          {id === 'total' ?
            (notYet + activatedToken) :
            id === 'activated' ?
            activatedToken : notYet
          }
        </Typography>
      </div>
      <PopoverTokenGiven 
        open={open} 
        anchorEl={anchorEl} 
        handlePopoverClose={handlePopoverClose} 
        idIcon={idIcon} 
      />
    </div>
  )
}