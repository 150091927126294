import axios from "axios"
import swal from 'sweetalert'
import Cookies from 'js-cookie'

export function submitLogin(email, password, setidUser, setfinishAuth, setEmail, setPassword) {
  return (dispatch, getState) => {
    const Form = new FormData();
    Form.append('username', email);
    Form.append('password', password);

    axios({
      method: 'POST',
      url: 'https://maupintar.id/api/user/generate_auth_cookie',
      data: Form
    })
    .then(response => {
      Cookies.set('id-mp', response.data.user.id, { expires: 7 });
      Cookies.set('pic-mp', response.data.user.avatar, { expires: 7 });
      Cookies.set('email-mp', response.data.user.email, { expires: 7 });
      Cookies.set('cks-mp', response.data.cookie, { expires: 7 });
      setidUser(response.data.user.id);
      
      let accForm = new FormData();
      accForm.append('id_user', response.data.user.id);
      accForm.append('cookie', response.data.cookie);

      return axios({
        method: 'POST',
        url: 'https://maupintar.id/app_api/auth_cookie_test.php',
        data: accForm
      })
    })
    .then(response => {
      setfinishAuth(true);
    })
    .catch(error => {
      swal("Oops!", "Email atau password tidak sesuai", "error");
      setEmail('');
      setPassword('');
    })
  }
}

export function getFinishAuth(idUser, setfinishGetProfile, setEmail, setPassword) {
  return (dispatch, getState) => {
    const Form = new FormData();
    Form.append('id_user', idUser);
    axios({
      method: 'POST',
      url: 'https://maupintar.id/app_api/get_user_profile.php',
      data: Form
    })
    .then(response => {
      Cookies.set('rls-mp', response.data[0].user_roles[0], { expires: 7 });
      setfinishGetProfile(true)
    })
    .catch(error => {
      console.log('error get profile >>>> ', error);
      swal("Sorry!", "Please try again!", "error");
      Cookies.remove('id-mp');
      Cookies.remove('pic-mp');
      Cookies.remove('email-mp');
      Cookies.remove('cks-mp');
    })
    .finally(() => {
      setEmail('');
      setPassword('');
    })
  }
}