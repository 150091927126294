import React, {useState} from 'react';
import { makeStyles, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  btn: {
    border: 0,
    borderRadius: 25,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    height: 40,
    padding: '0 10px',
    margin: 8,
    color: '#FF9E00',
    backgroundColor: '#FFFFFF',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#FF9E00',
    fontSize: '20px'
  }
}))

export default function FAQPage() {
  const classes = useStyles()
  const [active, setActive] = useState('Teacher')

  const activeButton = {
    backgroundImage: 'linear-gradient(to right, #FF5200 60%, #FFBB00)',
    color: 'white'
  }

  return (
    <div className={"page mainpage"}>
      <Typography variant="h4">FAQ</Typography>
      <Typography variant="h5">(Frequently Ask Question)</Typography>
      <div>
        <Button 
          style={active === 'Teacher' ? activeButton : {backgroundColor: '#FFFFFF'}} 
          className={classes.btn + " btnActive"} 
          onClick={() => setActive('Teacher')} 
        >
          Teacher
        </Button>
        <Button 
          style={active === 'Student' ? activeButton : {backgroundColor: '#FFFFFF'}} 
          className={classes.btn + " btnActive"} 
          onClick={() => setActive('Student')} 
        >
          Student
        </Button>
      </div>
    </div>
  )
}