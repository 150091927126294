import axios from 'axios';

export function getDashboard(progressForm) {
  return (dispatch, getState) => {
    axios({
      method: 'POST',
      url: 'https://maupintar.id/app_api/get_dashboard_progress.php',
      data: progressForm
    })
    .then(response => {
      let studentTemp = response.data.detail
      // Untuk menghitung rekap statistik per nama siswa dan menambahkan key subRows untuk materi yang dimiliki siswa tsb
      for (let student in studentTemp) {
        studentTemp[student]["totalTopic"] = 0
        studentTemp[student]["doneTopic"] = 0
        studentTemp[student]["totalQuiz"] = 0
        studentTemp[student]["doneQuiz"] = 0
        studentTemp[student]["subRows"] = []
        for (let j = 0; j < studentTemp[student].course_progress.length; j++) {
          studentTemp[student]["subRows"].push({
            id: studentTemp[student].course_progress[j].id_course,
            name: studentTemp[student].course_progress[j].course_name,
            qtyVideo: 0,
            percVideo: 0,
            qtyQuiz: 0,
            percQuiz: 0,
            totalQuiz: 0,
            totalVideo: 0,
            point: null,
            subRows: []
          })
          for (let k = 0; k < studentTemp[student].course_progress[j].progress.length; k++) {
            if (studentTemp[student].course_progress[j].progress[k].type.includes('topic')) {
              studentTemp[student]["totalTopic"]++
              if (studentTemp[student].course_progress[j].progress[k].status === 1) {
                studentTemp[student]["doneTopic"]++
              }
            } else if (studentTemp[student].course_progress[j].progress[k].type.includes('quiz')) {
              studentTemp[student]["totalQuiz"]++
              if (Array.isArray(studentTemp[student].course_progress[j].progress[k].status)) {
                studentTemp[student]["doneQuiz"]++
              }
            }
          }
        }
        studentTemp[student]["percTopic"] = Math.round((studentTemp[student]["doneTopic"] / studentTemp[student]["totalTopic"]) * 10000) / 100
        studentTemp[student]["percQuiz"] = Math.round((studentTemp[student]["doneQuiz"] / studentTemp[student]["totalQuiz"]) * 10000) / 100
      }

      // Untuk menambahkan subRows pada tiap-tiap lesson
      for (let student in studentTemp) {
        for (let j = 0; j < studentTemp[student].course_progress.length; j++) {
          for (let k = 0; k < studentTemp[student].course_progress[j].progress.length; k++) {
            if (studentTemp[student].course_progress[j].progress[k].type.includes('lessons')) {
              studentTemp[student].subRows[j].subRows.push({
                course_id: studentTemp[student].course_progress[j].id_course,
                id: studentTemp[student].course_progress[j].progress[k].post_id,
                name: studentTemp[student].course_progress[j].progress[k].strand_name,
                qtyVideo: 0,
                percVideo: 0,
                qtyQuiz: 0,
                percQuiz: 0,
                totalQuiz: 0,
                totalVideo: 0,
                point: null,
                subRows: []
              })
            }
          }
        }
      }

      // Untuk menambahkan key subRows pada tiap topic
      for (let student in studentTemp) {
        for (let j = 0; j < studentTemp[student].course_progress.length; j++) {
          for (let k = 0; k < studentTemp[student].course_progress[j].progress.length; k++) {
            if (studentTemp[student].course_progress[j].progress[k].type.includes('topic')) {
              for (let m = 0; m < studentTemp[student].subRows[j].subRows.length; m++) {
                if ((studentTemp[student].course_progress[j].id_course === studentTemp[student].subRows[j].subRows[m].course_id) &&
                  (studentTemp[student].course_progress[j].progress[k].lesson_id === studentTemp[student].subRows[j].subRows[m].id)
                ) {
                  studentTemp[student].subRows[j].subRows[m].subRows.push({
                    course_id: studentTemp[student].course_progress[j].id_course,
                    lesson_id: studentTemp[student].course_progress[j].progress[k].lesson_id,
                    id: studentTemp[student].course_progress[j].progress[k].post_id,
                    name: studentTemp[student].course_progress[j].progress[k].lesson_name,
                    qtyVideo: 0,
                    percVideo: 0,
                    qtyQuiz: 0,
                    point: null,
                    percQuiz: 0,
                    totalQuiz: 0,
                    totalVideo: 0
                  })
                }
              }
            }
          }
        }
      }

      // Menghitung rekap statistik per topicnya
      for (let student in studentTemp) {
        for (let j = 0; j < studentTemp[student].course_progress.length; j++) {
          for (let k = 0; k < studentTemp[student].course_progress[j].progress.length; k++) {
            for (let m = 0; m < studentTemp[student].subRows[j].subRows.length; m++) {
              for (let n = 0; n < studentTemp[student].subRows[j].subRows[m].subRows.length; n++) {
                if (studentTemp[student].course_progress[j].progress[k].type.includes('topic') &&
                studentTemp[student].course_progress[j].progress[k].lesson_id === studentTemp[student].subRows[j].subRows[m].subRows[n].lesson_id &&
                studentTemp[student].course_progress[j].progress[k].post_id === studentTemp[student].subRows[j].subRows[m].subRows[n].id) {
                  studentTemp[student].subRows[j].subRows[m].subRows[n]["totalVideo"]++
                  if (studentTemp[student].course_progress[j].progress[k].status === 1) {
                    studentTemp[student].subRows[j].subRows[m].subRows[n].qtyVideo++
                    studentTemp[student].subRows[j].subRows[m].subRows[n].percVideo = Math.round((studentTemp[student].subRows[j].subRows[m].subRows[n].qtyVideo / studentTemp[student].subRows[j].subRows[m].subRows[n].totalVideo) * 10000) / 100
                  }
                } else if (studentTemp[student].course_progress[j].progress[k].type.includes('quiz') &&
                studentTemp[student].course_progress[j].progress[k].lesson_id === studentTemp[student].subRows[j].subRows[m].subRows[n].lesson_id &&
                studentTemp[student].course_progress[j].progress[k].topic_id === studentTemp[student].subRows[j].subRows[m].subRows[n].id) {
                  studentTemp[student].subRows[j].subRows[m].subRows[n]["totalQuiz"]++
                  if (Array.isArray(studentTemp[student].course_progress[j].progress[k].status)) {
                    studentTemp[student].subRows[j].subRows[m].subRows[n].qtyQuiz++
                    studentTemp[student].subRows[j].subRows[m].subRows[n].percQuiz = Math.round((studentTemp[student].subRows[j].subRows[m].subRows[n].qtyQuiz / studentTemp[student].subRows[j].subRows[m].subRows[n].totalQuiz) * 10000) / 100
                  }
                }
              }
            }
          }
        }
      }

      // Menghitung data statistik per lessonnya
      for (let student in studentTemp) {
        for (let j = 0; j < studentTemp[student].subRows.length; j++) {
          for (let k = 0; k < studentTemp[student].subRows[j].subRows.length; k++) {
            for (let m = 0; m < studentTemp[student].subRows[j].subRows[k].subRows.length; m++) {
              studentTemp[student].subRows[j].subRows[k].qtyQuiz += studentTemp[student].subRows[j].subRows[k].subRows[m].qtyQuiz
              studentTemp[student].subRows[j].subRows[k].qtyVideo += studentTemp[student].subRows[j].subRows[k].subRows[m].qtyVideo
              studentTemp[student].subRows[j].subRows[k].totalQuiz += studentTemp[student].subRows[j].subRows[k].subRows[m].totalQuiz
              studentTemp[student].subRows[j].subRows[k].totalVideo += studentTemp[student].subRows[j].subRows[k].subRows[m].totalVideo
            }
            studentTemp[student].subRows[j].subRows[k].percQuiz = Math.round((studentTemp[student].subRows[j].subRows[k].qtyQuiz / studentTemp[student].subRows[j].subRows[k].totalQuiz) * 10000) / 100
            studentTemp[student].subRows[j].subRows[k].percVideo = Math.round((studentTemp[student].subRows[j].subRows[k].qtyVideo / studentTemp[student].subRows[j].subRows[k].totalVideo) * 10000) / 100
          }
        }
      }

      // Menghitung data statistik per materinya
      for (let student in studentTemp) {
        for (let j = 0; j < studentTemp[student].subRows.length; j++) {
          for (let k = 0; k < studentTemp[student].subRows[j].subRows.length; k++) {
            studentTemp[student].subRows[j].qtyQuiz += studentTemp[student].subRows[j].subRows[k].qtyQuiz
            studentTemp[student].subRows[j].qtyVideo += studentTemp[student].subRows[j].subRows[k].qtyVideo
            studentTemp[student].subRows[j].totalQuiz += studentTemp[student].subRows[j].subRows[k].totalQuiz
            studentTemp[student].subRows[j].totalVideo += studentTemp[student].subRows[j].subRows[k].totalVideo
          }
          studentTemp[student].subRows[j].percQuiz = Math.round((studentTemp[student].subRows[j].qtyQuiz / studentTemp[student].subRows[j].totalQuiz) * 10000) / 100
          studentTemp[student].subRows[j].percVideo = Math.round((studentTemp[student].subRows[j].qtyVideo / studentTemp[student].subRows[j].totalVideo) * 10000) / 100
        }
      }

      // Menambahkan key leaderboard poin per siswa
      let arrTemp = [];
      for (let student in studentTemp) {
        studentTemp[student].leaderboard_point = Number(studentTemp[student].leaderboard_point);
        arrTemp.push(studentTemp[student]);
      };
      arrTemp.sort(function (a, b) {
        return b.leaderboard_point - a.leaderboard_point
      })
      
      dispatch({ type: 'GET/TOKENS', payload: response.data.token });
      dispatch({ type: 'GET/STUDENTS', payload: arrTemp });
      dispatch({ type: 'GET/SUMMARIES', payload: response.data.total });
    })
    .catch(error => {
      console.log('error get dashboard >>>> ', error);
    })
    .finally(() => {
      dispatch({ type: 'UPDATE/LOADING_SUMMARY', payload: false });
    })
  }
}
