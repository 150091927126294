import React from 'react'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  titlePage: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    "@media (max-width: 1024px)": {
      display: 'none'
    }
  },
  columnCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    "@media (max-width: 900px)": {
      justifyContent: 'flex-start',
      paddingTop: '20vh'
    }
  }
}))

export default function ImageLogin() {
  const classes = useStyles()

  return (
    <div className={classes.titlePage + " row-center"}>
      <div className={classes.columnCenter}>
        <img 
          src="https://maupintar.id/images/teacher/Maupintar-3.png" 
          width="100%" 
          height="100%" 
          alt="maupintar" 
        />
      </div>
    </div>
  )
}