import React, {useEffect, useState} from 'react'
import {Modal, Fade, Paper, Typography, Backdrop, makeStyles} from '@material-ui/core'
import {TokenTable, DownloadCSV} from '../index'
import {colors} from '../../styles'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '23px',
    fontWeight: 'bold',
    color: colors.orange,
    textAlign: 'center',
    marginBottom: '20px',
    "@media (max-width: 768px)": {
      fontSize: '18px'
    }
  },
  paper: {
    width: '50%', 
    padding: '20px', 
    maxHeight: '80%', 
    overflowY: 'auto',
    "@media (max-width: 768px)": {
      width: '80%'
    }
  }
}))

export default function ModalListToken({showOpening, setshowOpening, tokenPendings}) {
  const classes = useStyles()
  const [lists, setlists] = useState([])

  useEffect(() => {
    let output = []
    for (let i = 0; i < tokenPendings.length; i++) {
      output.push({
        col1: tokenPendings[i].code_gen,
        col2: tokenPendings[i].course_coll
      })
    }
    setlists(output)
  }, [tokenPendings])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`modal row-center`}
      open={showOpening}
      onClose={() => setshowOpening(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showOpening}>
        <Paper className={`${classes.paper} column-up`}>
          <Typography variant="h1" className={classes.title}>
            Daftar token yang belum diaktivasi
          </Typography>
          <Typography variant="h1" className={classes.title}>
            Silakan minta para Siswa untuk segera melakukan aktivasi!
          </Typography>
          <DownloadCSV data={lists} />
          <TokenTable lists={lists} />
        </Paper>
      </Fade>
    </Modal>
  )
}