import React from 'react'
import {Typography, Table, TableBody, TableRow, TableCell, TextField, 
  Button, makeStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  containerForm: {
    padding: '1vw',
    "@media (max-width: 600px)": {
      marginBottom: '5vw'
    }
  },
  leftField: {
    width: '20vw',
    "@media (max-width: 768px)": {
      width: '25vw'
    },
    "@media (max-width: 600px)": {
      width: '60vw'
    }
  },
  btn: {
    backgroundColor: 'rgba(1, 61, 120, 1)',
    color: 'white',
    borderRadius: '25px',
    transition: theme.transitions.create(
      ["transform"],
      { duration: theme.transitions.duration.complex }
    ),
    "&:hover": {
      transform: 'scale(0.9)',
      backgroundColor: 'rgba(1, 61, 120, 0.75)'
    }
  }
}))

export default function PersonalInformation({
  updateProfile, profileUpdated, handleChange, profile
}) {
  const classes = useStyles()

  return (
    <div className={`${classes.containerForm} column-up`}>
      <Typography
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          color: 'rgb(250, 76, 6)'
        }}
      >
        Data Pribadi
      </Typography>
      <form method="POST" onSubmit={updateProfile}>
        <Table style={{marginBottom: '1vw'}}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography style={{textAlign: 'right'}}>
                  <strong>Nama Lengkap:</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <TextField
                  className={classes.leftField}
                  value={profileUpdated.student_name}
                  onChange={(e, category) => handleChange(e, 'student_name')}
                  required
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography style={{textAlign: 'right'}}>
                  <strong>Username:</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <TextField
                  className={classes.leftField}
                  value={profileUpdated.username}
                  onChange={(e, category) => handleChange(e, 'username')}
                  required
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography style={{textAlign: 'right'}}>
                  <strong>Lokasi:</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <TextField
                  className={classes.leftField}
                  value={profileUpdated.homecity}
                  onChange={(e, category) => handleChange(e, 'homecity')}
                  required
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography style={{textAlign: 'right'}}>
                  <strong>Sekolah:</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <TextField
                  className={classes.leftField}
                  value={profile.school}
                  disabled
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography style={{textAlign: 'right'}}>
                  <strong>Jabatan:</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <TextField
                  className={classes.leftField}
                  value={profile.role}
                  disabled
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography style={{textAlign: 'right'}}>
                  <strong>Nomor HP/WA:</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <TextField
                  className={classes.leftField}
                  value={profileUpdated.student_phone}
                  onChange={(e, category) => handleChange(e, 'student_phone')}
                  required
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Button variant="contained"
          className={classes.btn}
          type="submit"
          data-intro='Klik "Update Profile" untuk menyimpan perubahan data pribadi' 
          data-step="1"
        >
          Simpan Data Pribadi
        </Button>
      </form>
    </div>
  )
}