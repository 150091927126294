import React from 'react'
import {TableRow, TableCell, makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    textAlign: 'center',
    fontSize: '16px',
    "@media (max-width: 500px)": {
      fontSize: '12px'
    }
  },
  content: {
    fontSize: '16px',
    "@media (max-width: 500px)": {
      fontSize: '12px'
    }
  },
  zeroValue: {
    color: 'red',
    fontWeight: 'bold'
  },
  viewedValue: {
    color: 'rgb(3,172,14)',
    fontWeight: 'bold'
  }
}))

export default function TableData({row, i}) {
  const classes = useStyles()

  return (
    <TableRow {...row.getRowProps()} style={{borderTop: '1px solid #b8b8b8'}}>
      {row.cells.map(cell => {
        return (
          <TableCell 
            className={
              ((cell.column.Header !== "Nama Siswa") ? classes.alignCenter : '') + ` ${classes.content} ` +
              ((cell.render('Cell').props.cell.value === 0 || (isNaN(cell.render('Cell').props.cell.value) && (cell.column.Header === "Jumlah Video" ||
              cell.column.Header === "% Video" || cell.column.Header === "Jumlah Kuis" ||
              cell.column.Header === "% Kuis"))) ? classes.zeroValue : ((cell.column.Header === "Jumlah Kuis" || cell.column.Header === "Jumlah Video") ?
              classes.viewedValue : ''))
            } 
            {...cell.getCellProps()}
          >
            {(isNaN(cell.render('Cell').props.cell.value) && (cell.column.Header === "Jumlah Video" ||
            cell.column.Header === "% Video" || cell.column.Header === "Jumlah Kuis" ||
            cell.column.Header === "% Kuis")) ? 0 : 
            ((cell.column.Header === "Point" && cell.render('Cell').props.cell.value) ? 
            cell.render('Cell').props.cell.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 
            cell.render('Cell'))}
          </TableCell>
        )
      })}
    </TableRow>
  )
}