import axios from 'axios';
import Cookies from 'js-cookie';

export function getTour() {
  return (dispatch, getState) => {
    const Form = new FormData();
    Form.append('id_user', Cookies.get('id-mp'));

    axios({
      method: 'POST',
      url: 'https://maupintar.id/app_api/get_dashboard_tour.php',
      data: Form
    })
    .then(response => {
      dispatch({ type: 'GET/TOUR', payload: response.data[0] })
    })
    .catch(error => {
      console.log('error get dashboard >>>> ', error)
    })
  }
}

export function updateTour(form) {
  return (dispatch, getState) => {
    axios({
      method: 'POST',
      url: 'https://maupintar.id/app_api/set_dashboard_tour.php',
      data: form
    })
    .then(response => {
      dispatch(getTour());
    })
    .catch(error => {
      console.log('error update app tour >>>> ', error)
    })
  }
}