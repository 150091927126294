import axios from "axios"

export function getSteps() {
  return ['Welcome', 'Step 1:', 'Step 2:', 'Step 3:', 'Step 4:', 'Step 5:', 'Step 6:'];
}

export function getStepContent(step) {
  switch (step) {
    case 0:
      return `Pengantar dari COO Maupintar (1:49)`;
    case 1:
      return '4 Langkah Memulai Ekosistem Maupintar (1:51)';
    case 2:
      return `Perkenalkan, Mentari Tutor Video (MTV)!`;
    case 3:
      return `MTV: Sebuah Pendekatan "Blended Learning" dalam Ruang Ajar`;
    case 4:
      return `Video Tutorial #1 : Mengenal Dasbhoard Guru di Maupintar`;
    case 5:
      return `Video Tutorial #2 : Mengenal Fitur Halaman MTV di Dashboard Guru`;
    case 6:
      return `Video Tutorial #3 : Profesional Development dalam Halaman Pengembangan Diri`;
    default:
      return 'Unknown step';
  }
}

export function submitTeacher(
  lineForm, dataForm, profile, setShowAlert, getTeachers, 
  showError, setDataForm, setLineForm
) {
  let fullname = [];
  let email = [];
  let kelas = [];
  let role = [];
  let phone = [];
  let form = new FormData();
  for (let i = 0; i < lineForm.length; i++) {
    fullname.push(dataForm[i].name);
    email.push(dataForm[i].email);
    let isAllClass = false;
    for (let j = 0; j < dataForm[i].class.length; j++) {
      if (dataForm[i].class[j] === 'Semua Kelas') {
        kelas.push(['1','2','3','4','5','6']);
        isAllClass = true;
      };
    };
    if (!isAllClass) {
      kelas.push(dataForm[i].class);
    };
    role.push(dataForm[i].role);
    phone.push(dataForm[i].wa);
  };
  form.append('fullname', JSON.stringify(fullname));
  form.append('email', JSON.stringify(email));
  form.append('kelas', JSON.stringify(kelas));
  form.append('role', JSON.stringify(role));
  form.append('phone', JSON.stringify(phone));
  form.append('sid', profile.id_school);
  
  axios({
    method: 'POST',
    url: 'https://maupintar.id/dashboard/orderdashmp.php',
    data: form
  })
    .then(response => {
      setShowAlert(true)
      getTeachers()
    })
    .catch(error => {
      showError();
    })
    .finally(() => {
      setDataForm([{
        id: 1,
        name: '',
        email: '',
        wa: '',
        role: '',
        class: []
      }])
      setLineForm([1])
    })
}

export function changeInputValue(e, data, key, dataForm, setDataForm, grades) {
  let newData = [ ...dataForm ];
  let isAllClass = false;
  let temp;
  switch (key) {
    case 'name':
      // update name
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].id === data) {
          newData[i].name = e.target.value;
        };
      };    
      break;
    case 'email':
      // update email
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].id === data) {
          newData[i].email = e.target.value;
        };
      };
      break;
    case 'WA':
      // update WA
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].id === data) {
          newData[i].wa = e.target.value;
        };
      };
      break;
    case 'role':
      // update role
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].id === data) {
          newData[i].role = e.target.value;
        };
      };
      break;
    default:
      // update subject class
      for (let i = 0; i < e.target.value.length; i++) {
        if (e.target.value[i].includes('Semua Kelas')) {
          temp = grades;
          isAllClass = true;
        };
      }
      if (!isAllClass) {
        temp = e.target.value;
      };
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].id === data) {
          newData[i].class = [ ...temp ];
        };
      };
      break;
  };
  setDataForm(newData)
}