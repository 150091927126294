const initialState = {
  teachers: [],
  loading: true
}

export function teacherReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET/TEACHERS':
      return { ...state, teachers: action.payload }
    case 'UPDATE/LOADING':
      return { ...state, loading: action.payload }
    default:
      return state;
  }
}