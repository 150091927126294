import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Divider } from '@material-ui/core';
import swal from 'sweetalert';
import { LoadingPage } from './index';
import Cookies from 'js-cookie';
import introJs from 'intro.js';
import { getTour, updateTour, getToken, getProfile, getListTeachers } from '../actions';
import {ListMember, AddForm} from '../components'

export default function AddMember() {
  const dataTeachers = useSelector(state => state.teacherReducer.teachers);
  const [teachers, setTeachers] = useState(dataTeachers);
  const loading = useSelector(state => state.teacherReducer.loading);
  const [keyword, setKeyword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const profile = useSelector(state => state.profileReducer.profile);
  const history = useHistory();
  const dispatch = useDispatch();
  const tour = useSelector(state => state.tourReducer.status);

  useEffect(() => {
    // Cek apakah sudah login atau belum (bisa refactoring dengan menggunakan useContext)
    if (Cookies.get('cks-mp')) {
      dispatch(getToken());
      if (!tour) {
        dispatch(getTour());
      };
      if (!profile) {
        dispatch(getProfile(tour))
      }
    } else {
      history.push('/login');
    }
  }, []);
  
  useEffect(() => {
    if (profile) {
      getTeachers();
    }
  }, [profile])

  useEffect(() => {
    if (!loading) {
      if (showAlert) {
        swal("Selamat!", "Data Anda sukses ditambahkan!", "success")
          .then((value) => {
            setShowAlert(false)
          })
      };
      setTeachers(dataTeachers);
    };
  }, [loading]);

  useEffect(() => {
    // Untuk melakukan filter pencarian guru berdasarkan keyword yang dimasukkan
    if (keyword) {
      let dataFiltered;
      dataFiltered = dataTeachers.filter(data => 
        ((data.fullname && ((data.fullname).toLowerCase()).includes(keyword.toLowerCase())) || 
        (data.email && ((data.email).toLowerCase()).includes(keyword.toLowerCase())) || 
        (data.phone && data.phone.includes(keyword)) || 
        (data.role && ((data.role).toLowerCase()).includes(keyword.toLowerCase())) || 
        (data.level && data.level.includes(keyword))) && data
      );
      setTeachers(dataFiltered);
    } else {
      setTeachers(dataTeachers);
    };
  }, [keyword]);

  function getTeachers () {
    dispatch(getListTeachers(profile))
  };

  function showError() {
    swal("Mohon maaf!", "Data Anda gagal ditambahkan!", "error");
  };

  function intro() {
    // Untuk menjalankan fitur tour app ketika user membuka halaman tersebut utk yg pertama kali
    if (tour.add_member === '0') {
      introJs().start();
      const Form = new FormData();
      Form.append('id_user', Cookies.get('id-mp'));
      Form.append('step', 'add_member');
      dispatch(updateTour(Form));
    };
  }


  if (loading) {
    return (
      <LoadingPage />
    );
  };

  return (
    <div 
      className={"page mainpage"} 
      style={{
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between', 
        alignItems: 'center'
      }}
    >
      <ListMember 
        intro={intro} 
        keyword={keyword} 
        setKeyword={setKeyword} 
        teachers={teachers} 
      />
      <Divider/>
      <AddForm 
        intro={intro} 
        getTeachers={getTeachers} 
        setShowAlert={setShowAlert} 
        showError={showError} 
      />
    </div>
  );
};