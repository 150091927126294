import React from 'react';
import {makeStyles, Divider} from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import {InputName, InputEmail, InputPhone, InputRole, InputClass} from '../index'


const useStyles = makeStyles((theme) => ({
  renderForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  addForm: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    padding: '2vh 0'
  },
  removeBtn: {
    color: 'red',
    cursor: 'pointer'
  }
}));

export default function AddInput({ data, dataForm, changeValue, lineForm, removeForm, grades }) {
  const classes = useStyles();

  return (
    <div className={classes.renderForm}>
      <div className={classes.flexCenter}>
        <div className={classes.addForm}>
          <InputName data={data} dataForm={dataForm} changeValue={changeValue} />
          <InputEmail data={data} dataForm={dataForm} changeValue={changeValue} />
          <InputPhone data={data} dataForm={dataForm} changeValue={changeValue} /> 
          <InputRole data={data} dataForm={dataForm} changeValue={changeValue} />
          <InputClass data={data} dataForm={dataForm} changeValue={changeValue} grades={grades} />  
        </div>
        {(lineForm.length > 1) &&
        (
          <RemoveCircleIcon 
            fontSize="large" 
            className={classes.removeBtn + " btnActive"} 
            onClick={(id) => removeForm(data)}
          />
        )}
      </div>
      <Divider style={{width: '100%'}} />
    </div>
  )
};
