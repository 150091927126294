import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Typography from '@material-ui/core/Typography';
import { TableLearningBoard } from '../index';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%', 
    boxShadow: '3px 3px 10px 2px #D0D2C6', 
    borderRadius: '25px', 
    height: '54vh', 
    overflow: 'auto', 
    marginBottom: '5vh',
    padding: '2vw 2vw 0 2vw',
    minWidth: '300px',
    "@media (max-width: 1024px)": {
      height: '50vh'
    }
  },
  headerComponent: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    padding: '0 2vw',
    marginBottom: '1vw',
    height: '10%'
  },
  titleComponent: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '20px',
    "@media (max-width: 500px)": {
      fontSize: '14px'
    }
  },
  chart: {
    color: 'rgb(250, 76, 6)',
    margin: '0 3px',
    fontSize: '20px',
    "@media (max-width: 500px)": {
      fontSize: '14px'
    }
  }
}))

export default function LearningBoard({ intro, classSelected }) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <div className={classes.headerComponent}>
        <div className={classes.titleComponent}>
          <EqualizerIcon className={classes.chart}></EqualizerIcon>
          <Typography variant="h6" className={classes.title}>Statistik Pembelajaran</Typography>
        </div>
      </div>
      <TableLearningBoard data={classSelected}></TableLearningBoard>
    </Card>
  )
}