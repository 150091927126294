import React from 'react'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  containerBtn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '20%',
    "@media (max-width: 768px)": {
      width: '60%',
      flexDirection: 'row'
    },
    "@media (max-width: 600px)": {
      width: '70%',
      flexDirection: 'column'
    }
  },
  link: {
    textDecoration: 'none',
    width: '100%'
  },
  img: {
    margin: '0.5rem 0',
    cursor: 'pointer',
    transition: theme.transitions.create(
      ["transform"],
      { duration: theme.transitions.duration.complex }
    ),
    "&:hover": {
      transform: 'scale(1.2)'
    }
  }
}))

export default function ApplicationButton() {
  const classes = useStyles()

  return (
    <div className={classes.containerBtn}>
      <a 
        href="https://apps.apple.com/kz/app/maupintar/id1554416198" 
        target="_blank" 
        rel="noreferrer" 
        className={`${classes.link} row-center`}
      >
        <img 
          src="https://maupintar.id/images/teacher/applestore.png" 
          width="80%" 
          alt="applestore" 
          className={classes.img} 
        />
      </a>
      <a 
        href="https://play.google.com/store/apps/details?id=id.maupintar" 
        target="_blank" 
        rel="noreferrer" 
        className={`${classes.link} row-center`}
      >
        <img 
          src="https://maupintar.id/images/teacher/playstore.png" 
          width="80%" 
          alt="playstore" 
          className={classes.img} 
        />
      </a>
    </div>
  )
}