const initialState = {
  topic: null,
  video: null,
  quiz: null,
  students: [],
  tokens: [],
  summaries: [],
  loading: true
}

export function summaryReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET/SUMMARIES':
      return { ...state, summaries: action.payload };
    case 'GET/STUDENTS':
      return { ...state, students: action.payload };
    case 'GET/TOKENS':
      return { ...state, tokens: action.payload };
    case 'UPDATE/LOADING_SUMMARY':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}