import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { LoadingPage } from './index';
import parse from 'html-react-parser';
import { makeStyles, Typography, Button} from '@material-ui/core';
import swal from 'sweetalert';
import Cookies from 'js-cookie';
import {OptionalAnswer, ModalQuizResult} from '../components'
import {getQuestion} from '../actions'
import {useDispatch} from 'react-redux'

const useStyles = makeStyles((theme) => ({
  imgContainer: {
    width: '60%', 
    height: '60%',
    "@media (max-width: 600px)": {
      width: '100%', 
      height: '100%',
      marginBottom: '5vw'
    }
  },
  quizContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    "@media (max-width: 600px)": {
      flexDirection: 'column'
    }
  },
  btnSubmit: {
    backgroundColor: 'rgb(3,172,14)', 
    borderRadius: '20px', 
    padding: '0.5rem 2rem',
    transition: theme.transitions.create(
      ["transform", "background-color"],
      { duration: theme.transitions.duration.complex }
    ),
    "&:hover": {
      transform: 'scale(1.2)',
      backgroundColor: 'rgb(3,172,14)'
    }
  },
  btnDisable: {
    backgroundColor: 'rgba(3,172,14,0.4)', 
    borderRadius: '20px', 
    padding: '0.5rem 2rem'
  },
  quizPage: {
    padding: '2rem 0'
  },
  fontBtn: {
    fontWeight: 'bold', 
    color: 'white'
  },
  fontDisable: {
    color: 'gray'
  }
}))

export default function QuizPage() {
  const { course_id, quiz_id } = useParams();
  const [quiz, setQuiz] = useState(null);
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState(0);
  const classes = useStyles();
  const options = ['A', 'B', 'C', 'D', 'E'];
  const [selected, setSelected] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    // Cek apakah sudah login atau belum (bisa refactoring dengan menggunakan useContext)
    if (Cookies.get('cks-mp')) {
      dispatch(getQuestion(quiz_id, setQuiz, setLoading))
    } else {
      history.push('/login');
    }
  }, [])

  function checkAnswer() {
    if (quiz[current]._answerData[selected]._correct) {
      swal({
        title: "Selamat!",
        text: "Jawabanmu benar!",
        icon: "success",
        button: "Kunci Jawaban",
      })
      .then(response => {
        if (response) {
          setShowExplanation(true)
        } else {
          if (quiz.length-1 === current) {
            history.push(`/video/${course_id}`);
          } else {
            setCurrent(current+1);
          }
        }
      })
    } else {
      swal({
        title: "Oops!",
        text: "Jawabanmu salah!",
        icon: "error",
        button: "Kunci Jawaban",
      })
      .then(response => {
        if (response) {
          setShowExplanation(true)
        }
      })
    }
  }

  function closeExplanation() {
    if (quiz[current]._answerData[selected]._correct) {
      if (quiz.length-1 === current) {
        history.push(`/video/${course_id}`);
      } else {
        setCurrent(current+1);
      }
    }
    setShowExplanation(false);
  }

  if (loading) {
    return <LoadingPage />
  }

  return (
    <div className={"page mainpage"}>
      <div className={`${classes.quizPage} column-center`}>
        <div className={classes.quizContainer}>
          <div className={`${classes.imgContainer} column-center`}>
            {parse(quiz[current]._question)}
          </div>
          <OptionalAnswer 
            quiz={quiz} 
            current={current} 
            selected={selected} 
            setSelected={setSelected} 
            options={options}
          />
        </div>
        <Button 
          className={
            (selected === null) ? 
            classes.btnDisable : classes.btnSubmit
          } 
          style={{marginTop: '2rem'}} 
          onClick={checkAnswer} 
          disabled={selected === null}
        >
          <Typography 
            className={
              (selected === null) ? 
              classes.fontDisable : classes.fontBtn
            }
          >
            Submit
          </Typography>
        </Button>
        <ModalQuizResult 
          showExplanation={showExplanation} 
          closeExplanation={closeExplanation} 
          quiz={quiz} 
          current={current}
        />
      </div>
    </div>
  )
}