import React from 'react'
import {makeStyles, Typography} from '@material-ui/core'
import {LoadingPage} from '../../pages'
import {CardTopic, CardSubtopic} from '../index'

const useStyles = makeStyles((theme) => ({
  titleSubtopic: {
    fontWeight: 'bold',
    fontSize: '30px',
    "@media (max-width: 600px)": {
      fontSize: '16px'
    }
  }
}))

export default function ListTopic({
  content, chooseTopic, subtopic, active, loading, subtopics, openLearning
}) {
  const classes = useStyles()

  function renderSubtopic() {
    return subtopics.map((data, index) => {
      return (
        <CardSubtopic 
          key={index}
          data={data} 
          openLearning={openLearning}
        />
      )
    })
  }

  return (
    <div className={'row-up'}>
      <div className={'row-center'}
        style={{
          flexWrap: 'wrap',
          margin: '1vw',
          width: (active && ('50%'))
        }}
      >
        {(content.strand_list.length === 0) ?
        (
          <Typography>No Topic Available</Typography>
        ) :
        (
          content.strand_list.map((data, index) => {
            return (
              <CardTopic 
                key={index}
                data={data} 
                chooseTopic={chooseTopic} 
                active={active} 
                subtopic={subtopic} 
                index={index}
              />
            )
          })
        )}
      </div>
      {loading ?
      (
        <LoadingPage />
      ) :
      (
        active &&
        (
          <div className={'column-up'} style={{width: '70%', padding: '1vw'}}>
            <Typography variant="body1" className={classes.titleSubtopic}>
              {subtopic}
            </Typography>
            {renderSubtopic()}
          </div>
        )
      )}
    </div>
  )
}