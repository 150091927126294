import React, {useState, useEffect} from 'react'
import {Card, makeStyles} from '@material-ui/core';
import {useSelector} from 'react-redux'
import {AmountCourseContent} from '../index'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  card: {
    width: '30vw',
    height: '20vh',
    backgroundColor: 'rgba(255, 222, 209, 1)',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '0 2vw',
    borderRadius: '20px',
    boxShadow: '3px 3px 10px 2px #D0D2C6',
    margin: '2vh',
    minWidth: '90px',
    "@media (max-width: 1024px)": {
      width: '40vw',
      height: '10vh'
    },
    "@media (max-width: 765px)": {
      width: '90vw',
      height: '15vh'
    },
    "@media (max-width: 540px)": {
      width: '70vw',
      height: '80px',
      margin: '10px'
    }
  }
}))

export default function AmountCourse({ intro, classSelected }) {
  const classes = useStyles();
  const summaries = useSelector(state => state.summaryReducer.summaries);
  const [summaryUpdated, setSummaryUpdated] = useState(summaries)

  const [anchorEl, setAnchorEl] = useState(null);
  const [idIcon, setIdIcon] = useState('')

  const handlePopoverOpen = (event) => {
    setIdIcon(event.currentTarget.id)
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    intro()
  }, [])

  useEffect(() => {
    // Untuk menghitung rekap data jumlah coursenya
    let summaryTemp = {
      topic: 0,
      video: 0,
      quiz: 0
    }
    if (classSelected === 'all') {
      for (let i = 0; i < summaries.length; i++) {
        if (i > 0) {
          if (Number(summaries[i].grade) > Number(summaries[i].grade - 1)) {
            summaryTemp.topic = Number(summaries[i].topic)
          }
        } else {
          summaryTemp.topic = Number(summaries[i].topic)
        }
        summaryTemp.video = summaryTemp.video + Number(summaries[i].video)
        summaryTemp.quiz = summaryTemp.quiz + Number(summaries[i].quiz_1) + Number(summaries[i].quiz_2)
      }
    } else {
      for (let i = 0; i < summaries.length; i++) {
        if (Number(classSelected) === Number(summaries[i].grade)) {
          summaryTemp.topic = summaryTemp.topic + Number(summaries[i].topic)
          summaryTemp.video = summaryTemp.video + Number(summaries[i].video)
          summaryTemp.quiz = summaryTemp.quiz + Number(summaries[i].quiz_1) + Number(summaries[i].quiz_2)
        }
      }
    }
    setSummaryUpdated(summaryTemp)
  }, [classSelected])

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        {['topic', 'video', 'quiz'].map((value, index) => {
          return (
            <AmountCourseContent 
              handlePopoverClose={handlePopoverClose} 
              handlePopoverOpen={handlePopoverOpen} 
              id={value} 
              data={summaryUpdated}
              idIcon={idIcon}
              open={open}
              anchorEl={anchorEl} 
              key={index}
            />
          )
        })}
      </Card>
    </div>
  )
}