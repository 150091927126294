import React from 'react'
import {makeStyles, Typography} from '@material-ui/core'
import {ApplicationButton} from '../index'

const useStyles = makeStyles((theme) => ({
  containerContent: {
    display: 'flex', 
    justifyContent: 'space-evenly', 
    alignItems: 'center', 
    padding: '2rem',
    "@media (max-width: 768px)": {
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  imgIcon: {
    "@media (max-width: 768px)": {
      display: 'none'
    }
  },
  containerDescription: {
    width: '55%',
    "@media (max-width: 768px)": {
      width: '90%'
    }
  },
}))

export default function LibraryApplication() {
  const classes = useStyles()

  return (
    <div className={classes.containerContent}>
      <img 
        src="https://maupintar.id/images/teacher/download-app.png" 
        alt="download maupintar" 
        width="20%" 
        className={classes.imgIcon} 
      />
      <div className={classes.containerDescription}>
        <Typography variant="h5" align="left" style={{fontWeight: 'bold'}}>
          Download Aplikasi Maupintar
        </Typography>
        <Typography variant="h6" align="left">
          Silakan Login dengan menggunakan Email dan Password yang sama dengan Akun Dashboard Guru
        </Typography>
      </div>
      <ApplicationButton />
    </div>
  )
}