import React from 'react'
import {makeStyles, Popover, Typography} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paperTopic: {
    padding: theme.spacing(1),
    "@media (max-width: 500px)": {
      marginLeft: 0,
      marginRight: 50
    }
  },
  paperVideo: {
    height: 'auto',
    marginRight: -70,
    padding: theme.spacing(1),
    "@media (max-width: 500px)": {
      marginLeft: 0,
      marginRight: 50
    }
  },
  paperQuiz: {
    height: 'auto',
    marginLeft: 100,
    marginRight: -300,
    padding: theme.spacing(1),
    "@media (max-width: 500px)": {
      marginLeft: 50,
      marginRight: 50
    }
  }
}))

export default function PopoverAmountCourse({idIcon, open, anchorEl, handlePopoverClose}) {
  const classes = useStyles()

  return (
    <Popover
      id="mouse-over-popover"
      style={{
        width: '100%'
      }}
      className={classes.popover}
      classes={{
        paper: classes.paperTopic,
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: (idIcon === 'quiz' ? 'right' : 'left'),
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography>
        {idIcon === 'topic' ? 
          'Jumlah topik yang tersedia di pilihan kelas ini' :
          (idIcon === 'video' ?
            'Jumlah video yang tersedia di pilihan kelas ini' :
            'Jumlah kuis yang tersedia di pilihan kelas ini'
          )
        }
      </Typography>
    </Popover>
  )
}