import React from 'react'
import {IconButton, makeStyles} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import {colors} from '../../styles'

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    "@media (min-width: 1025px)": {
      display: 'none'
    }
  },
  btnMenu: {
    color: colors.orange
  },
  icons: {
    marginRight: '5px'
  }
}))

export default function MenuButton({setOpenMenu, openMenu}) {
  const classes = useStyles()

  function expandMenu() {
    if (openMenu) {
      setOpenMenu(false)
    } else {
      setOpenMenu(true)
    }
  }

  return (
    <IconButton
      onClick={expandMenu}
      className={classes.menuIcon}
      id="btn-menu"
    >
      <MenuIcon />
    </IconButton>
  )
}