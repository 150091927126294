import React, { useState, useEffect } from 'react';
import {Typography, makeStyles, Divider} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import {submitTeacher, changeInputValue} from '../../utils'
import {AddTeacherForm} from '../index'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontWeight: 'bold', 
    marginTop: '1vh',
    fontSize: 30,
    "@media (max-width: 767px)": {
      fontSize: 25
    }
  },
  descriptionText: {
    marginBottom: '1vh',
    fontSize: 20,
    "@media (max-width: 767px)": {
      fontSize: 16
    }
  }
}));


export default function AddForm({ getTeachers, setShowAlert, showError, intro }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);
  const [lineForm, setLineForm] = useState([1]);
  const [dataForm, setDataForm] = useState([{
    id: 1,
    name: '',
    email: '',
    wa: '',
    role: '',
    class: []
  }]);
  const grades = ['Semua Kelas', '1', '2', '3', '4', '5', '6'];
  const profile = useSelector(state => state.profileReducer.profile);

  function submit(e) {
    e.preventDefault()
    dispatch({ type: 'UPDATE/LOADING', payload: true })
    submitTeacher(lineForm, dataForm, profile, setShowAlert, getTeachers, showError, setDataForm, setLineForm)
  }

  function changeValue(e, data, key) {
    changeInputValue(e, data, key, dataForm, setDataForm, grades)
  };

  function addLineForm() {
    let newData = [ ...lineForm ];
    let temp = newData[newData.length - 1] + 1;
    let newDataForm = [ ...dataForm ];
    let tempDataForm = {
      id: temp,
      name: '',
      email: '',
      wa: '',
      role: '',
      class: []
    };
    newData.push(temp);
    setLineForm(newData);
    newDataForm.push(tempDataForm);
    setDataForm(newDataForm);
  };

  function removeForm(id) {
    let tempDataForm = [ ...dataForm ];
    let tempLineForm = [ ...lineForm ];
    let newLineForm = [];
    let newDataForm = [];
    for (let i = 0; i < tempLineForm.length; i++) {
      if (tempLineForm[i] !== id) {
        newLineForm.push(tempLineForm[i]);
        newDataForm.push(tempDataForm[i]);
      };
    };
    setLineForm(newLineForm);
    setDataForm(newDataForm);
  };

  useEffect(() => {
    intro()
  }, [])

  return (
    <div style={{ margin: '2vw' }}>
      <Typography className={classes.titleText}>Tambahkan Guru</Typography>
      <Typography className={classes.descriptionText}>
        Silahkan menambahkan anggota tim Anda yang akan mendapatkan hak akses ke Dashboard Maupintar
      </Typography>
      <Divider />
      <AddTeacherForm 
        submit={submit} 
        lineForm={lineForm} 
        dataForm={dataForm} 
        changeValue={changeValue} 
        removeForm={removeForm} 
        grades={grades} 
        addLineForm={addLineForm}
      />
    </div>
  )
}