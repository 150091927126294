import React from 'react'
import {Card, CardActionArea, CardMedia, CardContent, Typography, makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  cardActive: {
    borderStyle: 'solid',
    borderWidth: '0.5vw',
    borderColor: '#fa4c06'
  },
  cardTopic: {
    padding: '3vw 1vw 1vw 1vw', 
    margin: '1vw', 
    boxShadow: '0px 0px 20px 5px rgba(54, 53, 53, 0.3)',
    borderRadius: '20px',
    cursor: 'pointer',
    "@media (max-width: 600px)": {
      padding: '0'
    },
    "@media (min-width: 1025px)": {
      transition: theme.transitions.create(
        ['transform'],
        { duration: theme.transitions.duration.complex }
      ),
      "&:hover": {
        transform: 'scale(1.1)'
      }
    }
  },
  topicActive: {
    width: '14vw', 
    height: '14vw',
    "@media (max-width: 768px)": {
      width: '18vw', 
      height: '18vw'
    },
    "@media (max-width: 600px)": {
      width: '20vw', 
      height: '20vw'
    }
  },
  topicNonActive: {
    width: '18vw', 
    height: '18vw',
    "@media (max-width: 1024px)": {
      width: '22vw', 
      height: '22vw'
    },
    "@media (max-width: 768px)": {
      width: '30vw', 
      height: '30vw'
    },
    "@media (max-width: 600px)": {
      width: '40vw', 
      height: '40vw',
      margin: '2vw'
    }
  },
  actionArea: {
    "&:hover $focusHighlight": {
      opacity: 0
    }
  },
  focusHighlight: {},
  imgTopicActive: {
    height: '70px', 
    width: '70px',
    "@media (max-width: 600px)": {
      display: 'none'
    }
  },
  imgTopicNonactive: {
    height: '100px', 
    width: '100px', 
    marginBottom: '5px',
    "@media (max-width: 1024px)": {
      height: '14vw', 
      width: '14vw'
    },
    "@media (max-width: 768px)": {
      height: '18vw', 
      width: '18vw'
    },
    "@media (max-width: 600px)": {
      height: '20vw', 
      width: '20vw',
      marginTop: '2vw'
    }
  },
  contentActive: {
    "@media (max-width: 600px)": {
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  titleTopicActive: {
    fontSize: '14px',
    "@media (max-width: 600px)": {
      fontSize: '14px',
      textAlign: 'center'
    },
    "@media (max-width: 400px)": {
      fontSize: '10px'
    },
    "@media (max-width: 280px)": {
      fontSize: '8px'
    }
  },
  titleTopicNonactive: {
    fontSize: '20px',
    "@media (max-width: 600px)": {
      fontSize: '16px'
    },
    "@media (max-width: 400px)": {
      fontSize: '12px'
    },
    "@media (max-width: 280px)": {
      fontSize: '10px'
    }
  }
}))

export default function CardTopic({data, chooseTopic, active, subtopic, index}) {
  const classes = useStyles()

  return (
    <Card
      onClick={() => chooseTopic(data.strand_title, data.id)}
      className={
        ((data.strand_title === subtopic) && 
        classes.cardActive) + " " + classes.cardTopic + " " +
        (active ? classes.topicActive : classes.topicNonActive)
      }
      data-intro='Pilihan topic' data-step={index === 0 && "1"}
    >
      <CardActionArea classes={{
        root: classes.actionArea,
        focusHighlight: classes.focusHighlight
      }}
        style={{height: '100%', width: '100%'}}
      >
        <CardMedia>
          <img 
            src={data.strand_icon} 
            className={active ? classes.imgTopicActive : classes.imgTopicNonactive}
            alt="topik MTV"
          />
        </CardMedia>
        <CardContent className={(active ? classes.contentActive : '')}>
          <Typography 
            style={{fontWeight: 'bold'}}
            className={active ? classes.titleTopicActive : classes.titleTopicNonactive}
          >
            {data.strand_title.length <= 25 ?
            data.strand_title :
            data.strand_title.slice(0, 25) + '...'}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}