import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Typography, makeStyles} from '@material-ui/core';
import { LoadingPage } from './index';
import Cookies from 'js-cookie';
import {getModulDetail} from '../actions'
import {useDispatch} from 'react-redux'
import {ModulPlayer, ModulSummary} from '../components'

const useStyles = makeStyles((theme) => ({
  containerBanner: {
    padding: '5vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#070070',
    "@media (max-width: 767px)": {
      flexDirection: 'column-reverse'
    }
  },
  titleBanner: {
    fontWeight: 'bold',
    fontSize: 30,
    color: 'white',
    margin: '1vw',
    "@media (max-width: 767px)": {
      fontSize: 25
    }
  },
  imgBanner: {
    width: '40%',
    borderRadius: '15px',
    "@media (max-width: 767px)": {
      width: '80%'
    }
  }
}))

export default function ModulDetail() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const [modulDetail, setModulDetail] = useState(null)
  const { course_id, lesson_id } = useParams();

  useEffect(() => {
    // Cek apakah sudah login atau belum (bisa refactoring dengan menggunakan useContext)
    if (Cookies.get('cks-mp')) {
      dispatch(getModulDetail(course_id, lesson_id, setModulDetail))
    } else {
      history.push('/login');
    }
  }, [])

  if (!modulDetail) {
    return (
      <LoadingPage></LoadingPage>
    )
  }

  return (
    <div className={"page mainpage"}>
      <div className={classes.containerBanner}>
        <div>
          <Typography className={classes.titleBanner}>
            {modulDetail.course_title}
          </Typography>
        </div>
        <img 
          src={modulDetail.course_banner} 
          className={classes.imgBanner} 
          alt="produk VOD" 
        />
      </div>
      <div className={'column-up-down'} style={{marginTop: '1vw'}}>
        <ModulPlayer modulDetail={modulDetail} />
        <ModulSummary modulDetail={modulDetail} />
      </div>
    </div>
  )
}