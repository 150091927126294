import React from 'react'
import {Card, CardActionArea, CardMedia, CardContent, Typography, Button, makeStyles} from '@material-ui/core'
import {shadowCard, fontSize, colors} from '../../styles'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import ScheduleIcon from '@material-ui/icons/Schedule'
import LocationOnIcon from '@material-ui/icons/LocationOn'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '360px',
    height: '350px',
    boxShadow: shadowCard.grayShadow,
    margin: '1rem', 
    paddingBottom: '1rem',
    borderRadius: '20px',
    "@media (max-width: 1024px)": {
      height: '340px'
    },
    "@media (max-width: 768px)": {
      height: '330px',
      width: '330px'
    },
    "@media (max-width: 540px)": {
      width: '230px',
      height: '280px'
    }
  },
  img: {
    height: 'auto', 
    width: '100%'
  },
  containerTitle: {
    padding: '0 5px',
    marginTop: '10px',
    height: '60px',
    overflowY: 'auto',
    "@media (max-width: 540px)": {
      height: '50px'  
    }
  },
  titleCard: {
    fontWeight: 'bold',
    fontSize: fontSize.textBtn,
    "@media (max-width: 540px)": {
      fontSize: fontSize.subtitle
    }
  },
  stateEvent: {
    margin: '1vw 0',
  },
  symbol: {
    margin: '0 1vw',
    color: colors.orange
  },
  subtitleCard: {
    fontSize: fontSize.subtitle,
    "@media (max-width: 540px)": {
      fontSize: fontSize.subtitle
    }
  },
  btn: {
    border: 0,
    borderRadius: 25,
    boxShadow: shadowCard.orangeShadow,
    height: 40,
    padding: '0 15px',
    margin: '10px',
    color: colors.lightOrange,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: colors.lightOrange,
    fontSize: fontSize.textBtn,
    "@media (max-width: 768px)": {
      fontSize: '16px',
      padding: '0 5vw',
      boxShadow: 'none'
    },
    "@media (max-width: 500px)": {
      padding: '0 7vw'
    },
    "@media (min-width: 1025px)": {
      transition: theme.transitions.create(
        ["transform", "box-shadow"],
        { duration: theme.transitions.duration.complex }
      ),
      "&:hover": {
        transform: 'scale(0.9)',
        boxShadow: 'none'
      }
    }
  }
}))

export default function CardEvent({event, activeButton}) {
  const classes = useStyles()

  return (
    <Card className={`${classes.card} column-up-down`}>
      <CardActionArea>
        <CardMedia>
          <img 
            src={`https://hadiryuk.id/assets/event/${event.event_code}/${event.photoevent}`} 
            className={classes.img}
            alt="Hadiryuk"
          />
        </CardMedia>
        <CardContent style={{padding: '0 5px'}}>
          <div className={classes.containerTitle}>
            <Typography className={classes.titleCard}>
              {event.title}
            </Typography>
          </div>
          <div className={`${classes.stateEvent} row-left`}>
            <EventAvailableIcon className={classes.symbol} />
            <Typography 
              align="left" 
              color="textSecondary" 
              className={classes.subtitleCard}
            >
              {event.date_start}
            </Typography>
          </div>
          <div className={`${classes.stateEvent} row-left`}>
            <ScheduleIcon className={classes.symbol}></ScheduleIcon>
            <Typography 
              align="left" 
              color="textSecondary" 
              className={classes.subtitleCard}
            >
              {event.time_start.slice(0,5) + ' WIB'}
            </Typography>
          </div>
          <div className={`${classes.stateEvent} row-left`}>
            <LocationOnIcon className={classes.symbol}></LocationOnIcon>
            <Typography 
              align="left" 
              color="textSecondary" 
              className={classes.subtitleCard}
            >
              {event.location_place}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
      <a
        href={`https://hadiryuk.id/event/detail/${event.title_url}`} 
        target="_blank" 
        style={{textDecoration: 'none'}}
        rel="noreferrer"
      >
        <Button 
          style={activeButton} 
          className={classes.btn + " btnActive"}
        >
          Tiket & Detil
        </Button>
      </a>
    </Card>
  )
}