import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingPage() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      className={"page mainpage"}
    >
      <CircularProgress
        style={{
          width: '10vh',
          height: '10vh'
        }}
      />
    </div>
  )
}