import React, { useState, useEffect } from 'react';
import { makeStyles, Button, Card, CardMedia,
  Typography, CardActionArea, CardContent, CardActions
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
  btn: {
    border: 0,
    borderRadius: 25,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    height: 30,
    padding: '0 10px',
    margin: 8,
    color: '#FF9E00',
    backgroundColor: '#FFFFFF',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#FF9E00'
  },
  root: {
    width: '84vw',
    maxHeight: '100vh',
    overflow: 'auto'
  }
}))

export default function TopicPage() {
  const classes = useStyles()
  const materials = useSelector(state => state.topics)
  const [category, setCategory] = useState(materials)
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const history = useHistory();

  useEffect(() => {
    if (Cookies.get('cks-mp')) {
      let temp = materials.filter(data => {
        return data.categoryId === location.state.id
      })
      setCategory(temp[0].topics)
      setLoading(false)
    } else {
      history.push('/login');
    }
  }, [materials])

  if (loading) {
    return (
      <Typography>Loading....</Typography>
    )
  }

  return (
    <div className={classes.root}>
      <div className={'row-center'} style={{flexWrap: 'wrap'}}>
        {category.map((data, index) => {
          return (
            <Card 
              style={{
                width: '30vh', 
                height: '35vh', 
                boxShadow: '2px 2px 2px 2px #D0D2C6', 
                margin: '1rem', 
                padding: '1rem'
              }}
            >
              <CardActionArea>
                <CardMedia>
                  <img src={data.preview} alt="topic" style={{height: '15vh'}}></img>
                </CardMedia>
                <CardContent style={{paddingBottom: '0'}}>
                  <Typography variant="body1">
                    {data.title}
                  </Typography>
                  <Typography variant="body1">
                    Grade {data.class}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions className={'row-center'}>
                <Button 
                  style={{
                    backgroundColor: '#FF9E00', 
                    color: 'white'
                  }} 
                  className={classes.btn + " btnActive"} 
                >
                  Open
                </Button>
              </CardActions>
            </Card>
          )
        })}
      </div>
    </div>
  )
}