import React, {useEffect, useState, createRef} from 'react'
import {makeStyles, Typography} from '@material-ui/core'
import Cookies from 'js-cookie'
import {useDispatch, useSelector} from 'react-redux'
import {LoadingPage} from './index'
import {useHistory} from 'react-router-dom'
import introJs from 'intro.js'
import {getProfile, getDashboard, updateTour, getToken, updateNotification} from '../actions'
import swal from 'sweetalert'
import {colors, fontSize} from '../styles'
import {AmountCourse, TokenGiven, SelectClass, ModalOpening, Leaderboard} from '../components'

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    marginTop: '2vh'
  },
  general: {
    marginTop: '1rem',
    width: '100%'
  },
  reportStat: {
    width: '100%',
    flexWrap: 'wrap'
  },
  title: {
    margin: '2vh', 
    fontWeight: 'bold', 
    color: colors.orange,
    fontSize: fontSize.title,
    "@media (max-width: 540px)": {
      fontSize: fontSize.textBtn
    }
  }
}))

export default function DashboardPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const profile = useSelector(state => state.profileReducer.profile)
  const tour = useSelector(state => state.tourReducer.status)
  const loading = useSelector(state => state.summaryReducer.loading)
  const history = useHistory()
  const classProfile = [1,2,3,4,5,6]
  const [classSelected, setClassSelected] = useState('all')
  const [showOpening, setshowOpening] = useState(false)
  const [startIntro, setstartIntro] = useState(false)
  const [notYet, setNotYet] = useState(null)
  const shown = useSelector(state => state.tokenReducer.shown)
  const tokens = useSelector(state => state.summaryReducer.tokens)
  const [activatedToken, setActivatedToken] = useState(null)

  function getDataDashboard() {
    let progressForm = new FormData();
    progressForm.append('id_user', Cookies.get('id-mp'));
    progressForm.append('email', Cookies.get('email-mp'));
    progressForm.append('cookie', Cookies.get('cks-mp'));
    dispatch(getDashboard(progressForm));
  }

  useEffect(() => {
    // Cek apakah sudah login atau belum (bisa refactoring dengan menggunakan useContext)
    dispatch({ type: 'UPDATE/LOADING_SUMMARY', payload: true });
    if (Cookies.get('cks-mp')) {
      dispatch(getToken());
      if (!profile) {
        dispatch(getProfile(tour));
      }
      getDataDashboard();
    } else {
      history.push('/login');
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      // Cek apakah user baru login pertama kali ke aplikasi atau sudah ke sekian kalinya
      if (tour?.opening === '0') {
        setshowOpening(true);
        const Form = new FormData();
        Form.append('id_user', Cookies.get('id-mp'));
        Form.append('step', 'opening');
        dispatch(updateTour(Form));
      } else {
        setstartIntro(true);
      }
    };
  }, [loading])

  useEffect(() => {
    // Update data jumlah token yg sudah teraktivasi dan belum teraktivasi
    let notYet = 0
    let activated = 0
    if (classSelected === 'all') {
      for (let i = 0; i < tokens.length; i++) {
        notYet += Number(tokens[i].notyet)
        activated += Number(tokens[i].redeem)
      }
    } else {
      for (let i = 0; i < tokens.length; i++) {
        if (Number(classSelected) === Number(tokens[i].grade)) {
          notYet += Number(tokens[i].notyet)
          activated += Number(tokens[i].redeem)
        }
      }
    }
    setNotYet(notYet)
    setActivatedToken(activated)
  }, [classSelected, tokens])

  function closeOpening() {
    setshowOpening(false);
    seeLibrary();
  }

  function runIntro() {
    // Untuk menjalankan fitur tour app ketika user membuka halaman tersebut utk yg pertama kali
    if (startIntro && tour?.dashboard === '0') {
      introJs().start();
      const Form = new FormData();
      Form.append('id_user', Cookies.get('id-mp'));
      Form.append('step', 'dashboard');
      dispatch(updateTour(Form));
    }
  }

  useEffect(() => {
    if (startIntro) {
      intro()
    }
  }, [startIntro])
  
  function intro() {
    if (!notYet && notYet === 0) {
      dispatch(updateNotification())
      runIntro()
    } else {
      if (!shown) {
        showUndistributed(notYet)
      } else {
        runIntro()
      }
    }
  };

  function showUndistributed(value) {
    swal({
      icon: 'https://maupintar.id/images/teacher/mascot-kode-aktivasi.png',
      text: `Halo!
      Saat ini, masih ada ${value} Token MTV milik Sekolah yang belum teraktivasi. Harap pastikan Token MTV sudah terdistribusi kepada para siswa atau segera minta mereka untuk segera melakukan aktivasi.
      Terima kasih!`
    })
    .then(response => {
      dispatch(updateNotification())
      runIntro()
    })
  }

  function seeLibrary() {
    swal({
      icon: 'https://maupintar.id/images/teacher/mascot-hi.png',
      text: `Terima kasih! Anda dapat melihat kembali rangkaian video tutorial tersebut di Halaman Pustaka.`,
      className: 'img-see-library'
    })
    .then(response => {
      setstartIntro(true);
    })
  }

  if (loading) {
    return <LoadingPage />
  };

  return (
    <div className={"page mainpage"}>
      <Typography variant="h5" className={classes.title}>Dashboard Maupintar</Typography>
      <SelectClass classProfile={classProfile} classSelected={classSelected} setClassSelected={setClassSelected} />
      {startIntro &&
      (
        <div className={`${classes.root} row-center`}>
          <AmountCourse classSelected={classSelected} intro={intro}></AmountCourse>
          <TokenGiven 
            intro={intro} 
            notYet={notYet}
            activatedToken={activatedToken}
          />
        </div>
      )}
      {startIntro &&
      (
        <div className={`${classes.general} column-left-evenly`}>
          <div className={`${classes.reportStat} row-evenly`}>
            <Leaderboard intro={intro} classSelected={classSelected} />
          </div>
        </div>
      )}
      <ModalOpening showOpening={showOpening} closeOpening={closeOpening} />
    </div>
  )
}