import React from 'react'
import {videoLinks} from '../../utils'
import ReactPlayer from 'react-player'

export default function OpeningVideoPlayer({activeStep, handleEndVideo, showVideo}) {
  return (
    <ReactPlayer 
      url={videoLinks[activeStep]} 
      style={{boxShadow: '3px 3px 10px 2px #D0D2C6'}}
      onEnded={handleEndVideo}
      controls={true}
      width="100%"
      height="100%"
      playing={showVideo}
    />
  )
}