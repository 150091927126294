import React, {useState} from 'react'
import {makeStyles, Stepper, Typography, IconButton, Step, StepLabel, StepContent, Paper} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import {getSteps, getStepContent, imgThumbnails} from '../../utils'
import {OpeningVideoPlayer} from '../index'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import {colors} from '../../styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardStepper: {
    width: '40%',
    minWidth: '250px',
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(#fa4c06 40%, white 40%)',
    borderRadius: '15px',
    "@media (max-width: 1024px)": {
      width: '60%'
    },
    "@media (max-width: 540px)": {
      width: '80%'
    },
    "@media (max-width: 375px)": {
      width: '90%'
    }
  },
  containerStepper: {
    borderRadius: '15px',
    width: '70%',
    boxShadow: '0px 0px 20px 5px rgba(54, 53, 53, 0.3)'
  },
  hidden: {
    display: 'none'
  },
  containerVideo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
    height: '80%',
    "@media (max-width: 1024px)": {
      width: '80%',
      height: '50%'
    },
    "@media (max-width: 414px)": {
      width: '80%',
      height: '40%'
    }
  },
  btn: {
    alignSelf: 'flex-end',
    color: 'white',
    padding: '7px',
    fontSize: '20px',
    fontWeight: 'bold',
    backgroundColor: 'rgba(255, 82, 0, 1)',
    borderRadius: '50px',
    textTransform: 'none',
    margin: '1vw 0',
    transition: theme.transitions.create(
      ["transform"],
      {duration: theme.transitions.duration.complex}
    ),
    "&:hover": {
      transform: 'scale(0.9)',
      backgroundColor: 'rgba(255, 82, 0, 1)'
    }
  },
  title: {
    fontSize: '20px', 
    fontWeight: 'bold', 
    color: 'white', 
    width: '50%', 
    textAlign: 'center',
    lineHeight: '30px', 
    marginBottom: '20px',
    "@media (max-width: 540px)": {
      width: '70%'
    }
  },
  paperImg: {
    height: '30%',
    minHeight: '120px',
    width: '50%', 
    minWidth: '250px',
    marginLeft: '16px',
    marginBottom: '10px',
    backgroundPosition: 'center', 
    backgroundSize: 'cover',
    boxShadow: '3px 3px 10px 2px #D0D2C6',
    cursor: 'pointer',
    "@media (max-width: 450px)": {
      minWidth: '170px',
      minHeight: '80px'
    },
    "@media (max-width: 350px)": {
      minWidth: '150px',
      minHeight: '70px'
    }
  },
  subcontainerImg: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(87, 87, 87, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerBtnPlay: {
    backgroundColor: 'rgba(255, 82, 0, 1)', 
    borderRadius: '50px', 
    border: '3px solid rgba(217, 217, 217, 1)',
    width: '40px', 
    height: '40px',
    transition: theme.transitions.create(
      ['transform'],
      {duration: theme.transitions.duration.complex}
    ),
    "&:hover": {
      transform: 'scale(0.9)'
    }
  },
  btnPlay: {
    color: 'white',
    width: '70px', 
    height: '40px',
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  disableText: {
    width: '50px',
    cursor: 'default',
    color: 'gray',
    marginRight: '10px'
  },
  btnText: {
    cursor: 'pointer',
    width: '50px',
    marginRight: '10px'
  },
  textBack: {
    fontWeight: 'bolder'
  },
  textNext: {
    fontWeight: 'bolder', 
    color: colors.orange
  }
}))

export default function VideoStepper({closeOpening}) {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [showVideo, setshowVideo] = useState(false)

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      closeOpening()
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEndVideo = () => {
    if (activeStep === steps.length - 1) {
      setshowVideo(false)
    } else {
      handleNext()
      setshowVideo(false)
    }
  }

  return (
    <div className={classes.root}>
      <div className={!showVideo ? classes.cardStepper : classes.hidden}>
        <Typography variant="h3" className={classes.title}>
          Selamat Datang di Ekosistem Maupintar!
        </Typography>
        <Stepper 
          activeStep={activeStep} 
          orientation="vertical" 
          className={classes.containerStepper}
        >
          {steps.map((label, index) => {
            return (
              <Step>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Typography color="textSecondary" style={{marginBottom: '10px'}}>
                    {getStepContent(index)}
                  </Typography>
                  <Paper 
                    className={classes.paperImg} 
                    onClick={() => setshowVideo(true)} 
                    style={{backgroundImage: `url(${imgThumbnails[index]})`}}
                  >
                    <div className={classes.subcontainerImg}>
                      <div className={`${classes.containerBtnPlay} row-center`}>
                        <PlayArrowIcon className={classes.btnPlay} />
                      </div>
                    </div>
                  </Paper>
                  <div className={classes.actionsContainer}>
                    <div className={'row-left'}>
                      <div 
                        onClick={activeStep !== 0 && handleBack} 
                        className={
                          `${activeStep === 0 ? classes.disableText : classes.btnText} row-center`
                        }
                      >
                        <Typography className={classes.textBack}>
                          Back
                        </Typography>
                      </div>
                      <div onClick={handleNext} className={`${classes.btnText} row-center`}>
                        <Typography className={classes.textNext}>
                          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </StepContent>
              </Step>
            )
          })}
        </Stepper>
      </div>
      <div className={showVideo ? classes.containerVideo : classes.hidden}>
        <IconButton className={classes.btn} onClick={handleEndVideo}>
          <CloseIcon />
        </IconButton>
        <OpeningVideoPlayer 
          activeStep={activeStep} 
          handleEndVideo={handleEndVideo} 
          showVideo={showVideo} 
        />
      </div>
    </div>
  )
}