import React, {useState, useEffect} from 'react'
import {makeStyles, AppBar, Typography, IconButton} from '@material-ui/core'
import {useLocation, useHistory} from 'react-router-dom'
import {ButtonTopbar, ModalListToken, ModalCompleteToken} from '../index'
import {useSelector} from 'react-redux'
import {colors} from '../../styles'
import CancelIcon from '@material-ui/icons/Cancel'
import Cookies from 'js-cookie'

const useStyles = makeStyles((theme) => ({
  grow: {
    padding: '0 2vw'
  },
  logoMaupintar: {
    height: '8vh',
    alignSelf: 'center',
    margin: '1vh 0',
    cursor: 'pointer',
    "@media (max-width: 1024px)": {
      height: '50px'
    },
    "@media (max-width: 540px)": {
      height: '30px'
    }
  },
  textMenu: {
    color: colors.orange, 
    margin: '10px 0', 
    fontWeight: 'bold', 
    fontSize: '16px',
    cursor: 'pointer'
  },
  cancelIcon: {
    fontSize: '30px'
  }
}));

export default function TopNavbar() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [showOpening, setshowOpening] = useState(false)
  const tokenPendings = useSelector(state => state.tokenReducer.pending)
  const [openMenu, setOpenMenu] = useState(false)

  const login = {
    display: 'none'
  }

  function goToDashboard() {
    history.push('/dashboard')
  }

  function logout() {
    Cookies.remove('id-mp');
    Cookies.remove('pic-mp');
    Cookies.remove('email-mp');
    Cookies.remove('cks-mp');
    Cookies.remove('rls-mp');
    history.push('/login');
    setOpenMenu(false)
  }

  function clickNav(route) {
    history.push(`/${route}`)
    setOpenMenu(false)
  }

  useEffect(() => {
    if (openMenu) {
      document.getElementById('menu').classList.remove('hidden')
    } else {
      document.getElementById('menu').classList.add('hidden')
    }
  }, [openMenu])

  return (
    <div className={"page"} style={(location.pathname === '/login') ? login : {}}>
      <AppBar position="static" color="default" className={`${classes.grow} column-up`}>
        <div className={'row-between'} style={{width: '100%'}}>
          <img src={"https://maupintar.id/images/teacher/logo-maupintar.png"}
            className={classes.logoMaupintar}
            onClick={goToDashboard}
          />
          <ButtonTopbar setshowOpening={setshowOpening} setOpenMenu={setOpenMenu} openMenu={openMenu} />
        </div>
        <div id="menu" className={'hidden'}>
          <div onClick={(route) => clickNav('dashboard')}>
            <Typography className={`${classes.textMenu} w3-animate-left`}>Beranda</Typography>
          </div>
          <div onClick={(route) => clickNav('video')}>
            <Typography className={`${classes.textMenu} w3-animate-left`}>Mentari Tutor Video</Typography>
          </div>
          <div onClick={(route) => clickNav('belajar')}>
            <Typography className={`${classes.textMenu} w3-animate-left`}>Ruang Pengembangan Diri</Typography>
          </div>
          <div onClick={(route) => clickNav('add-member')}>
            <Typography className={`${classes.textMenu} w3-animate-left`}>Tambah Guru</Typography>
          </div>
          <div onClick={(route) => clickNav('library')}>
            <Typography className={`${classes.textMenu} w3-animate-left`}>Pustaka</Typography>
          </div>
          <div onClick={(route) => clickNav('profile')}>
            <Typography className={`${classes.textMenu} w3-animate-left`}>Profil</Typography>
          </div>
          <div onClick={logout}>
            <Typography className={`${classes.textMenu} w3-animate-left`}>Log Out</Typography>
          </div>
          <IconButton onClick={() => setOpenMenu(false)}>
            <CancelIcon color="error" />
          </IconButton>
        </div>
      </AppBar>
      {tokenPendings.length > 0 ?
      (
        <ModalListToken 
          showOpening={showOpening} 
          tokenPendings={tokenPendings} 
          setshowOpening={setshowOpening} 
        />
      ) :
      (
        <ModalCompleteToken showOpening={showOpening} setshowOpening={setshowOpening} />
      )}
    </div>
  );
}
