import React, { useEffect } from 'react';
import { Typography, makeStyles, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import {useDispatch} from 'react-redux';
import {getToken} from '../actions';
import {LibraryModul, LibraryApplication, LibraryVideo} from '../components'

const useStyles = makeStyles((theme) => ({
  libraryPage: {
    width: '100%',
    height: '100%'
  },
  titleStatement: {
    fontWeight: 'bold', 
    margin: '1vw', 
    fontSize: '30px',
    "@media (max-width: 500px)": {
      fontSize: '20px'
    },
    "@media (max-width: 350px)": {
      fontSize: '18px'
    }
  }
}))

export default function LibraryPage() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    // Cek apakah sudah login atau belum (bisa refactoring dengan menggunakan useContext)
    if (!Cookies.get('cks-mp')) {
      history.push('/login');
    } else {
      dispatch(getToken());
    }
  }, [])

  return (
    <div className={"page mainpage"}>
      <div className={classes.libraryPage}>
        <Typography className={classes.titleStatement}>
          Halaman Pustaka
        </Typography>
        <LibraryModul />
        <Divider />
        <LibraryApplication />
        <Divider style={{margin: '20px 0'}} />
        <Typography variant="h5" align="center" style={{fontWeight: 'bold'}}>
          Video Panduan Dashboard Guru Maupintar
        </Typography>
        <LibraryVideo />
      </div>
    </div>
  )
}