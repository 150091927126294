import React from 'react'
import {Modal, Fade, Button, Typography, makeStyles, Backdrop} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
    padding: theme.spacing(2, 4, 3),
    maxHeight: '90%',
    overflow: 'scroll'
  }
}))

export default function ModalMenu({showMenu, closeMenu}) {
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={"row-center modal"}
      open={showMenu}
      onClose={closeMenu}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showMenu}>
        <div className={`${classes.paper} column-center`}>

        </div>
      </Fade>
    </Modal>
  )
}