import React from 'react'
import {Card, CardMedia, CardContent, Typography, makeStyles} from '@material-ui/core'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '90%', 
    padding: '10px 20px', 
    margin: '1vw', 
    boxShadow: '0 3px 5px 2px rgba(232, 232, 232, .8)',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    backgroundColor: '#F4F4F4',
    cursor: 'pointer',
    "@media (min-width: 1025px)": {
      transition: theme.transitions.create(
        ['transform'],
        { duration: theme.transitions.duration.complex }
      ),
      "&:hover": {
        transform: 'scale(0.95)'
      }
    },
    "@media (max-width: 500px)": {
      width: '80%',
      padding: '5px 15px',
      margin: '5px'
    }
  },
  imageVideo: {
    color: '#fa4c06',
    marginRight: '15px',
    "@media (max-width: 500px)": {
      display: 'none'
    }
  },
  titleListSubtopic: {
    color: '#757575',
    fontWeight: 'bold',
    fontSize: '22px',
    "@media (max-width: 600px)": {
      fontSize: '16px'
    },
    "@media (max-width: 500px)": {
      fontSize: '10px'
    }
  },
  subtitleListSubtopic: {
    color: '#757575',
    fontSize: '14px',
    fontWeight: 'bold',
    "@media (max-width: 600px)": {
      fontSize: '12px'
    },
    "@media (max-width: 500px)": {
      fontSize: '8px'
    }
  }
}))

export default function CardSubtopic({data, openLearning}) {
  const classes = useStyles()
  
  return (
    <Card className={classes.card} onClick={() => openLearning(data.id)}>
      <CardMedia>
        <OndemandVideoIcon
          fontSize='large'
          className={classes.imageVideo}
        />
      </CardMedia>
      <CardContent style={{padding: '0'}}>
        <Typography className={classes.titleListSubtopic} align="left">
          {data.title.rendered}
        </Typography>
        <Typography align="left" className={classes.subtitleListSubtopic}>
          {Math.floor(data.video_duration / 60)} minutes
        </Typography>
      </CardContent>
    </Card>
  )
}