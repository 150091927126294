import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Typography, Button, makeStyles } from '@material-ui/core';
import ReactPlayer from 'react-player';
import axios from 'axios';
import Cookies from 'js-cookie';
import { LoadingPage } from './index';
import introJs from 'intro.js';
import { useSelector, useDispatch } from 'react-redux';
import { getTour, updateTour } from '../actions';

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: '#fa4c06',
    padding: '0 2vw',
    color: 'white',
    borderRadius: '25px',
    boxShadow: '0px 0px 20px 5px rgba(54, 53, 53, 0.3)',
    marginBottom: '3vw',
    "@media (max-width: 600px)": {
      padding: '0 5vw'
    },
    transition: theme.transitions.create(
      ['transform', 'background-color' ],
      { duration: theme.transitions.duration.complex }
    ),
    "&:hover": {
      transform: 'scale(1.2)',
      backgroundColor: '#fa4c06'
    }
  },
  btnDisable: {
    backgroundColor: 'rgba(250, 76, 6, 0.4)',
    padding: '0 2vw',
    marginBottom: '3vw',
    borderRadius: '25px',
    boxShadow: '0px 0px 20px 5px rgba(54, 53, 53, 0.3)',
    "@media (max-width: 600px)": {
      padding: '0 5vw'
    }
  },
  containerQuizDirection: {
    margin: '1rem 0',
    width: '40vw',
    "@media (max-width: 768px)": {
      width: '60vw'
    },
    "@media (max-width: 600px)": {
      width: '90vw'
    }
  },
  imgQuizDirection: {
    height: '10vw',
    margin: '0 1vw',
    "@media (max-width: 600px)": {
      height: '100px',
      margin: '0 10px'
    }
  },
  titleQuizTime: {
    color: '#fa4c06',
    fontWeight: 'bold',
    marginBottom: '1vw',
    fontSize: '25px',
    "@media (max-width: 600px)": {
      fontSize: '16px'
    }
  },
  titlePage: {
    margin: '2vw', 
    fontWeight: 'bold', 
    color: '#fa4c06', 
    fontSize: '35px',
    "@media (max-width: 600px)": {
      fontSize: '16px'
    }
  },
  contentQuiz: {
    color: 'rgba(51, 51, 51, 1)', 
    textAlign: 'left',
    fontSize: '20px',
    "@media (max-width: 600px)": {
      fontSize: '12px'
    }
  },
  btnText: {
    fontSize: '20px',
    "@media (max-width: 600px)": {
      fontSize: '14px'
    }
  }
}))

export default function LearningPage() {
  const classes = useStyles();
  const history = useHistory();
  const [detailMtv, setDetailMtv] = useState(null);
  const tour = useSelector(state => state.tourReducer.status);
  const dispatch = useDispatch();
  const { course_id, lesson_id } = useParams();

  useEffect(() => {
    if (Cookies.get('cks-mp')) {
      if (!tour) {
        dispatch(getTour());
      };
      const Form = new FormData();
      Form.append('course_id', course_id);
      Form.append('lesson_id', lesson_id);
      Form.append('id_user', Cookies.get('id-mp'));
  
      axios({
        method: 'POST',
        url: 'https://maupintar.id/app_api/get_mtv_topic_content.php',
        data: Form
      })
        .then(response => {
          setDetailMtv(response.data[0]);
        })
        .catch(error => {
          console.log('error get detail MTV >>>> ', error);
        })
    } else {
      history.push('/login');
    }
  }, [])

  useEffect(() => {
    if (detailMtv) {
      intro();
    };
  }, [detailMtv]);

  function goToQuiz() {
    history.push(`/video/${course_id}/${lesson_id}/${detailMtv.lesson_quiz}`)
  }

  function intro() {
    // Untuk menjalankan fitur tour app ketika user membuka halaman tersebut utk yg pertama kali
    if (tour.learning === '0') {
      introJs().start();
      const Form = new FormData();
      Form.append('id_user', Cookies.get('id-mp'));
      Form.append('step', 'learning');
      dispatch(updateTour(Form));
    };
  }

  if (!detailMtv) {
    return <LoadingPage />
  }
  
  return (
    <div className={"page mainpage"}>
      <div className={'column-center'}>
        <Typography className={classes.titlePage}>{detailMtv.lesson_title}</Typography>
        <div data-intro='Klik tombol "Play" untuk melihat materi video' data-step="1">
          <ReactPlayer 
            style={{boxShadow: '0px 0px 20px 5px rgba(54, 53, 53, 0.3)'}} 
            width={window.innerWidth <= 768 ? "90vw" : "640px"} 
            height="auto" 
            url={detailMtv.lesson_content} 
            controls={true}
          />
        </div>
        <div className={`${classes.containerQuizDirection} row-center`}>
          <img 
            src="https://maupintar.id/images/teacher/mascot-senang.png"  
            className={classes.imgQuizDirection} 
          />
          <div className={'column-left'} style={{margin: '0 1vw'}}>
            <Typography className={classes.titleQuizTime}>
              Quiz Time
            </Typography>
            <Typography className={classes.contentQuiz}>
              Great! You've just finished the video.
            </Typography>
            <Typography className={classes.contentQuiz}>
              Let’s ask our student to finished the quiz to check their understanding
            </Typography>
          </div>
        </div>
        <Button 
          className={
            detailMtv.lesson_quiz.includes('wd') ? 
            classes.btnDisable : classes.btn
          } 
          onClick={goToQuiz}
          data-intro='Klik tombol "Start Quiz" untuk melihat kuis-kuis yang tersedia' data-step="2"
          disabled={detailMtv.lesson_quiz.includes('wd')}
        >
          <Typography variant="h6" className={classes.btnText}>
            Start Quiz
          </Typography>
        </Button>
      </div>
    </div>
  )
}