import React from 'react'
import {Card, CardActionArea, CardMedia, CardContent, Typography,
  CardActions, Button, makeStyles
} from '@material-ui/core'
import {shadowCard, colors, fontSize} from '../../styles'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '290px', 
    height: '270px', 
    boxShadow: shadowCard.grayShadow, 
    margin: '1rem', 
    paddingBottom: '1rem',
    borderRadius: '20px',
    "@media (max-width: 1024px)": {
      width: '260px', 
      height: '250px'
    },
    "@media (max-width: 767px)": {
      width: '260px', 
      height: '250px'
    },
    "@media (max-width: 540px)": {
      width: '160px', 
      height: '180px'
    },
    "@media (max-width: 414px)": {
      width: '150px', 
      height: '170px'
    },
    "@media (max-width: 320px)": {
      width: '250px', 
      height: '230px'
    },
    "@media (max-width: 280px)": {
      width: '200px', 
      height: '200px'
    }
  },
  btn: {
    border: 0,
    borderRadius: 25,
    boxShadow: shadowCard.orangeShadow,
    height: 40,
    padding: '0 2vw',
    margin: '10px',
    color: colors.lightOrange,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: colors.lightOrange,
    fontSize: fontSize.textBtn,
    "@media (max-width: 1024px)": {
      boxShadow: 'none'
    },
    "@media (max-width: 767px)": {
      padding: '0 10px'
    },
    "@media (max-width: 540px)": {
      fontSize: fontSize.caption,
      padding: '0 10px',
      height: 25
    },
    "@media (max-width: 320px)": {
      fontSize: fontSize.subtitle,
      padding: '10px'
    },
    "@media (max-width: 280px)": {
      fontSize: fontSize.caption
    },
    transition: theme.transitions.create(
      ['transform', 'bow-shadow'],
      { duration: theme.transitions.duration.complex }
    ),
    "&:hover": {
      transform: 'scale(0.9)',
      boxShadow: 'none'
    }
  },
  title: {
    fontWeight: 'bold',
    fontSize: fontSize.textBtn,
    "@media (max-width: 540px)": {
      fontSize: fontSize.caption
    },
    "@media (max-width: 320px)": {
      fontSize: fontSize.subtitle
    },
    "@media (max-width: 280px)": {
      fontSize: fontSize.caption
    }
  },
  containerTitle: {
    padding: '0 5px',
    marginTop: '10px',
    height: '30px',
    overflowY: 'auto',
    "@media (max-width: 500px)": {
      height: '40px',
      marginTop: '5px'
    },
    "@media (max-width: 280px)": {
      height: '30px'
    }
  }
}))

export default function CardMTV({index, video, viewCourse, activeButton}) {
  const classes = useStyles()

  return (
    <Card 
      className={`${classes.card} column-up-down`} 
      data-intro='Daftar materi MTV yang dapat Anda akses per kategori kelas yang telah Anda pilih' 
      data-step={index === 0 && "2"}
    >
      <CardActionArea>
        <CardMedia>
          <img src={video.featured_image} style={{width: '100%'}} />
        </CardMedia>
        <CardContent className={classes.containerTitle}>
          <Typography variant="h6" className={classes.title}>
            {video.title.rendered}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions style={{padding: '0'}} className={'row-center'}>
        <Button 
          style={activeButton}
          className={classes.btn + " btnActive"}
          onClick={() => viewCourse(video)}
        >
          Lihat Detil
        </Button>
      </CardActions>
    </Card>
  )
}