import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMTVDetail, getSubTopics } from '../actions/videoAction';
import { LoadingPage } from './index';
import Cookies from 'js-cookie';
import introJs from 'intro.js';
import { getTour, updateTour } from '../actions';
import {MTVContentBanner, ListTopic} from '../components'

export default function MTVDetail() {
  const { mtv_id } = useParams();
  const dispatch = useDispatch();
  const content = useSelector(state => state.videoReducer.mtvContent);
  const loading = useSelector(state => state.videoReducer.loadingMtvContent);
  const openLoading = useSelector(state => state.videoReducer.openingLoaderMtvDetail);
  const [active, setActive] = useState(false);
  const subtopics = useSelector(state => state.videoReducer.subtopics);
  const subtopic = useSelector(state => state.videoReducer.subtopicTitle);
  const history = useHistory();
  const tour = useSelector(state => state.tourReducer.status);

  useEffect(() => {
    // Cek apakah sudah login atau belum (bisa refactoring dengan menggunakan useContext)
    if (Cookies.get('cks-mp')) {
      getContents();
      if (!tour) {
        dispatch(getTour());
      };
    } else {
      history.push('/login');
    };
  }, []);

  useEffect(() => {
    if (subtopics) {
      dispatch({ type: 'UPDATE/LOADING_MTV_CONTENT', payload: false });
    };
  }, [subtopics]);

  function chooseTopic(title, id) {
    if (subtopic !== title) {
      dispatch({ type: 'UPDATE/LOADING_MTV_CONTENT', payload: true });
      let form = new FormData();
      form.append('course_id', mtv_id);
      form.append('strand_id', id);
      form.append('id_user', Cookies.get('id-mp'));
      dispatch(getSubTopics(form));
      
      dispatch({ type: 'UPDATE/SUBTOPIC_TITLE', payload: title });
    }
    setActive(true);
  };

  function openLearning(id) {
    history.push(`/video/${mtv_id}/${id}`);
  };

  function getContents () {
    const Form = new FormData();
    Form.append('course_id', mtv_id);
    dispatch(getMTVDetail(Form));
  };

  function intro() {
    // Untuk menjalankan fitur tour app ketika user membuka halaman tersebut utk yg pertama kali
    if (tour.mtv_detail === '0') {
      introJs().start();
      const Form = new FormData();
      Form.append('id_user', Cookies.get('id-mp'));
      Form.append('step', 'mtv_detail');
      dispatch(updateTour(Form));
    };
  };

  useEffect(() => {
    if (!openLoading) {
      intro();
    };
  }, [openLoading]);

  if (!content) {
    return (
      <LoadingPage />
    )
  }

  return (
    <div className={"page mainpage"}>
      <MTVContentBanner content={content} />
      <ListTopic 
        content={content} 
        chooseTopic={chooseTopic} 
        subtopic={subtopic} 
        active={active} 
        loading={loading} 
        subtopics={subtopics} 
        openLearning={openLearning} 
      />
    </div>
  )
}