import React from 'react'
import {Card, CardActionArea, CardMedia, CardContent, Typography, 
  CardActions, Button, makeStyles
} from '@material-ui/core'
import {shadowCard, fontSize, colors} from '../../styles'
import parse from 'html-react-parser'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '360px',
    height: '350px',
    boxShadow: shadowCard.grayShadow,
    margin: '1rem', 
    paddingBottom: '1rem',
    borderRadius: '20px',
    "@media (max-width: 1024px)": {
      height: '340px'
    },
    "@media (max-width: 768px)": {
      height: '330px',
      width: '330px'
    },
    "@media (max-width: 540px)": {
      width: '230px',
      height: '280px'
    }
  },
  img: {
    height: 'auto', 
    width: '100%'
  },
  containerTitle: {
    padding: '0 5px',
    marginTop: '10px',
    height: '60px',
    overflowY: 'auto',
    "@media (max-width: 540px)": {
      height: '50px'  
    }
  },
  titleCard: {
    fontWeight: 'bold',
    fontSize: fontSize.textBtn,
    "@media (max-width: 540px)": {
      fontSize: fontSize.subtitle
    }
  },
  btn: {
    border: 0,
    borderRadius: 25,
    boxShadow: shadowCard.orangeShadow,
    height: 40,
    padding: '0 15px',
    margin: '10px',
    color: colors.lightOrange,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: colors.lightOrange,
    fontSize: fontSize.textBtn,
    "@media (max-width: 768px)": {
      fontSize: '16px',
      padding: '0 5vw',
      boxShadow: 'none'
    },
    "@media (max-width: 500px)": {
      padding: '0 7vw'
    },
    "@media (min-width: 1025px)": {
      transition: theme.transitions.create(
        ["transform", "box-shadow"],
        { duration: theme.transitions.duration.complex }
      ),
      "&:hover": {
        transform: 'scale(0.9)',
        boxShadow: 'none'
      }
    }
  }
}))

export default function CardVideoInspiratif({getModulDetail, video, activeButton}) {
  const classes = useStyles()

  return (
    <Card 
      className={`${classes.card} column-up-down`}
      onClick={() => getModulDetail(video)}
    >
      <CardActionArea>
        <CardMedia>
          <img 
            src={video.featured_image} 
            alt="Video Inspirasi" 
            className={classes.img} 
          />
        </CardMedia>
        <CardContent className={classes.containerTitle}>
          <Typography className={classes.titleCard}>
            {parse(video.title.rendered)}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={'row-center'}>
        <Button 
          style={activeButton} 
          className={classes.btn + " btnActive"} 
        >
          Lihat Detil
        </Button>
      </CardActions>
    </Card>
  )
}