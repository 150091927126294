import React from 'react'
import {TextField, makeStyles} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  textInput: {
    width: '25vw',
    minWidth: '250px'
  },
  input: {
    fontSize: 14
  },
  labelRoot: {
    fontSize: 14,
    textAlign: 'left'
  }
}))

export default function InputName({data, dataForm, changeValue}) {
  const classes = useStyles()

  return (
    <TextField 
      id={data}
      label="Nama Lengkap"
      type="text"
      className={classes.textInput}
      inputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        classes: {
          root: classes.labelRoot
        }
      }}
      value={(dataForm.filter(name => (
          name.id === data && name.name
        ))[0]) ?
        (
          (dataForm.filter(name => (
            name.id === data && name.name
          ))[0]).name
        ) : null}
      onChange={(e, id, key) => changeValue(e, data, 'name')}
      required
    />
  )
}