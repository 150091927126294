const initialState = {
  profile: null,
  loading: true
}

export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET/PROFILE':
      return { ...state, profile: action.payload };
    case 'UPDATE/LOADING_PROFILE':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}