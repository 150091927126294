import React from 'react'
import {FormControl, InputLabel, Select, Input, MenuItem, 
  Checkbox, ListItemText, FormHelperText, makeStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '25vw',
    minWidth: '250px'
  },
  labelRoot: {
    fontSize: 14,
    textAlign: 'left'
  },
  input: {
    fontSize: 14
  }
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function InputClass({data, dataForm, changeValue, grades}) {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-mutiple-checkbox-label" className={classes.labelRoot}>
        Pilih Kelas
      </InputLabel>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id={data}
        multiple
        value={(dataForm.filter(dataClass => (
            dataClass.id === data && dataClass.class
          ))[0]) ?
          (
            dataForm.filter(dataClass => (
              dataClass.id === data && dataClass.class
            ))[0].class
          ) : []}
        onChange={(e, id, key) => changeValue(e, data, 'class')}
        className={classes.labelRoot}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
        required
      >
        {grades.map((dataClass) => (
          <MenuItem key={dataClass} value={dataClass} className={classes.input}>
            <Checkbox 
              className={classes.input} 
              checked={
                dataForm.filter(dataFilter => dataFilter.id === data)[0].class.indexOf(dataClass) > -1
              } 
            />
            <ListItemText 
              primary={
                (dataClass === 'Semua Kelas') ? 
                dataClass : 'Kelas ' + dataClass
              } 
              className={classes.input} 
            />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id="my-helper-text">
        Pilih level yang berhak Tim akses
      </FormHelperText>
    </FormControl>
  )
}