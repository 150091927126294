import React from 'react'
import {Typography} from '@material-ui/core'

export default function EmptyMTV() {
  return (
    <div className={'column-center'} style={{width: '100%', height: '100%'}}>
      <img 
        src="https://maupintar.id/images/teacher/mascot-mtv.svg" 
        width={window.innerWidth < 600 ? "70%" : "30%"} 
        style={{margin: '1vw'}}
      />
      <Typography variant="h4" style={{color: 'rgb(250, 76, 6)', fontWeight: 'bold', margin: '1vw'}}>
        Anda belum memiliki produk ini
      </Typography>
    </div>
  )
}