import axios from 'axios'
import Cookies from 'js-cookie'

export function getToken() {
  return (dispatch, getState) => {
    const Form = new FormData()
    Form.append('id_user', Cookies.get('id-mp'))

    axios({
      method: 'POST',
      url: 'https://maupintar.id/app_api/get_token_list.php',
      data: Form
    })
    .then(response => {
      const temp = response.data.filter(data => {
        return data.is_redeemed === '0'
      })
      dispatch({type: 'GET/PENDING_TOKEN', payload: temp})
    })
    .catch(error => {
      console.log('error get Token >>>> ', error)
    })
  }
}

export function updateNotification() {
  return (dispatch, getState) => {
    dispatch({type: 'SET/SHOWN_TOKEN', payload: true})
  }
}