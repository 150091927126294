import React from 'react'
import {makeStyles} from '@material-ui/core'
import {libraryVideos} from '../../utils'
import {LibraryVideoContent} from '../index'

const useStyles = makeStyles((theme) => ({
  containerVideo: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    flexWrap: 'wrap',
    padding: '20px 0',
    "@media (max-width: 400px)": {
      padding: '20px'
    }
  }
}))

export default function LibraryVideo() {
  const classes = useStyles()

  return (
    <div className={classes.containerVideo}>
      {libraryVideos.map((content, index) => {
        return (
          <LibraryVideoContent key={index} content={content} />
        )
      })}
    </div>
  )
}