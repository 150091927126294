import React from 'react'
import {Typography, Card, CardContent, makeStyles} from '@material-ui/core'
import parse from 'html-react-parser'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '30vw', 
    minHeight: '5vh', 
    boxShadow: '0px 0px 20px 5px rgba(54, 53, 53, 0.3)', 
    margin: '2rem', 
    borderRadius: '20px',
    cursor: 'pointer',
    "@media (max-width: 600px)": {
      width: '80vw'
    },
    transition: theme.transitions.create(
      ["transform"],
      { duration: theme.transitions.duration.complex }
    ),
    "&:hover": {
      transform: 'scale(1.2)'
    }
  },
  btnActive: {
    backgroundColor: 'rgb(250, 76, 6)',
    color: 'white'
  }
}))

export default function OptionalAnswer({quiz, current, selected, setSelected, options}) {
  const classes = useStyles()

  return (
    <div>
      <Typography variant="h5" style={{fontWeight: 'bold', marginBottom: '1vw'}}>
        Your answer is ...
      </Typography>
      {quiz[current]._answerData.map((data, index) => {
        return (
          <Card 
            className={classes.card + ' column-left ' + (index === selected ? classes.btnActive : '')}
            onClick={() => setSelected(index)}
            key={index}
          >
            <CardContent style={{padding: '1rem'}} className={"answer row-center"}>
              {`${options[index]}.  `} {parse(data._answer)}
            </CardContent>
          </Card>
        )
      })}
    </div>
  )
}