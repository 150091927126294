import React from 'react'
import {Typography, makeStyles} from '@material-ui/core'
import ReactPlayer from 'react-player'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontWeight: 'bold', 
    color: '#fa4c06', 
    margin: '1vw 0',
    fontSize: 30,
    "@media (max-width: 767px)": {
      fontSize: 20
    }
  }
}))

export default function ModulPlayer({modulDetail}) {
  const classes = useStyles()

  return (
    <div className={'column-center'}>
      <Typography className={classes.titleText}>
        Video Materi
      </Typography>
      <ReactPlayer 
        url={modulDetail.lesson_content} 
        controls={true} width={window.innerWidth <= 768 ? "90vw" : "640px"} 
        height="auto"
      />
    </div>
  )
}