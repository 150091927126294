import React, {useEffect} from 'react'
import {Typography, makeStyles, Divider} from '@material-ui/core'
import {SearchInput, TableTeacher} from '../index'

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontWeight: 'bold', 
    marginTop: '1vh',
    fontSize: 30,
    "@media (max-width: 767px)": {
      fontSize: 25
    }
  },
  descriptionText: {
    marginBottom: '1vh',
    fontSize: 20,
    "@media (max-width: 767px)": {
      fontSize: 16
    }
  },
  root: {
    margin: '2vw',
    width: '100%'
  }
}));


export default function ListMember({ setKeyword, teachers, intro, keyword }) {
  const classes = useStyles();

  useEffect(() => {
    intro()
  }, [])

  return (
    <div className={classes.root}>
      <Typography className={classes.titleText}>Daftar Guru</Typography>
      <Typography className={classes.descriptionText}>
        Daftar Tim Pengajar dan Manajemen Sekolah yang mendapat akses terhadap Dashboard Maupintar
      </Typography>
      <Divider />
      <SearchInput setKeyword={setKeyword} keyword={keyword} />
      <TableTeacher teachers={teachers} />
    </div>
  )
}