import React from 'react'
import parse from 'html-react-parser'
import {makeStyles, Typography} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#070070',
    "@media (max-width: 767px)": {
      flexDirection: 'column-reverse'
    }
  },
  titleBanner: {
    fontSize: 35,
    "@media (max-width: 946px)": {
      fontSize: 25
    },
    "@media (max-width: 700px)": {
      fontSize: 20
    },
    "@media (max-width: 280px)": {
      fontSize: 16
    }
  },
  contentBanner: {
    fontSize: 25,
    "@media (max-width: 946px)": {
      fontSize: 20
    },
    "@media (max-width: 700px)": {
      fontSize: 15
    }
  },
  imgBanner: {
    width: '40%',
    borderRadius: '15px',
    "@media (max-width: 767px)": {
      width: '70%',
      margin: '2vw'
    }
  }
}))

export default function MTVContentBanner({content}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        <Typography 
          style={{fontWeight: 'bold', color: 'white'}} 
          className={classes.titleBanner}
        >
          {content.course_info.course_title}
        </Typography>
        <div
          style={{color: 'white'}}
          className={classes.contentBanner}
        >
          {parse(content.course_info.course_description.replace(
            'Materi ini akan mengajak Kamu', 'Materi ini akan mengajak Siswa'
          ))}
        </div>
      </div>
      <img 
        src={content.course_info.course_image} 
        className={classes.imgBanner} 
        alt="produk MTV" 
      />
    </div>
  )
}