import React from 'react'
import {makeStyles, Card, Typography} from '@material-ui/core'
import MenuBookIcon from '@material-ui/icons/MenuBook'

const useStyles = makeStyles((theme) => ({
  containerBtn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '20%',
    "@media (max-width: 768px)": {
      width: '60%',
      flexDirection: 'row'
    },
    "@media (max-width: 600px)": {
      width: '70%',
      flexDirection: 'column'
    }
  },
  link: {
    textDecoration: 'none',
    width: '100%'
  },
  card: { 
    width: '100%',
    minHeight: '5vh', 
    boxShadow: '0px 0px 20px 5px rgba(54, 53, 53, 0.3)', 
    margin: '0.5rem', 
    borderRadius: '20px',
    padding: '1rem',
    cursor: 'pointer',
    backgroundColor: 'rgb(250, 76, 6)',
    color: 'white',
    "@media (min-width: 1025px)": {
      transition: theme.transitions.create(
        ["transform"],
        { duration: theme.transitions.duration.complex }
      ),
      "&:hover": {
        transform: 'scale(1.2)'
      }
    },
    "@media (max-width: 1024px)": {
      height: '50px',
      padding: '0'
    }
  }
}))

export default function ModulButton() {
  const classes = useStyles()

  return (
    <div className={classes.containerBtn}>
      <a 
        href="https://maupintar.id/documents/dashboard-teacher/Panduan-Guru-Sekolah.pdf" 
        target="_blank" 
        className={`${classes.link} row-center`} 
        rel="noreferrer"
      >
        <Card className={`${classes.card} row-evenly`}>
          <MenuBookIcon />
          <Typography variant="body1">Modul Guru</Typography>
        </Card>
      </a>
      <a 
        href="https://maupintar.id/documents/dashboard-teacher/Modul-Siswa-Maupintar-MTV.pdf" 
        target="_blank" 
        className={`${classes.link} row-center`} 
        rel="noreferrer"
      >
        <Card className={`${classes.card} row-evenly`}>
          <MenuBookIcon />
          <Typography variant="body1">Modul Siswa</Typography>
        </Card>
      </a>
    </div>
  )
}